/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderInterceptor } from './interceptors/http-spinner.interceptor';
import { DdosHttpInterceptor } from './interceptors/http.interceptors';
import { DdosErrorHandler } from './interceptors/ddos-error.interceptor';
import { SharedModule } from './components/shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, SharedModule, HttpClientModule],
	providers: [
		{ provide: ErrorHandler, useClass: DdosErrorHandler },
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
		{ provide: HTTP_INTERCEPTORS, useClass: DdosHttpInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

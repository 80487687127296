/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '@src/app/components/shared/services/dialog/confirm-dialog.service';

@Component({
	selector: 'ddos-ui-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialog implements OnInit {

	@Output() actionConfirmed: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
		public dialogRef: MatDialogRef<ConfirmDialog>
	) {}

	ngOnInit(): void {}

	confirm() {
		this.dialogRef.close(true);
		this.actionConfirmed.emit();
	}

	cancel() {
		this.dialogRef.close(false);
	}
}

export const DDOS_CONFIRM_DIALOG_DEFAULT_CONFIGS: MatDialogConfig = {
	width: '500px',
	disableClose: true,
};

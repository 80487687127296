/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TabItem } from '@src/app/routing-constants';
import { AppLayoutStore } from '@src/app/stores/app-layout/app-layout.store';
import { MatRadioChange } from '@angular/material/radio';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { SessionStore } from '@src/app/stores/session/session.store';
import { CompanyInfo, Userinfo } from '@src/app/stores/session/session.model';
import { GlobalGetsStore } from '@src/app/stores/global-gets.store';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@src/environments/environment';
import { CompanyOfferType } from '@app/stores/common.model';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ConfirmDialogData, ConfirmDialogService } from '../services/dialog/confirm-dialog.service';
import { ConfirmDialog } from '../confirm-dialog/confirm.component';

@Component({
	selector: 'ddos-ui-shell',
	templateUrl: './shell.component.html',
	styleUrls: ['./shell.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit, OnDestroy {
	user$: Observable<Userinfo>;

	assignedCompanies$: Observable<CompanyInfo[]>;

	currentCompany$: Observable<CompanyInfo>;

	tabItems$: Observable<TabItem[]>;

	globalGetsDone$: Observable<boolean>;

	isAuthenticatedAndReady$: Observable<boolean>;

	readonly userBtnTooltip: string = 'User settings';

	@ViewChild('sidebar', { static: true }) sidebar: SidebarComponent;

	private arborModalSub: Subscription;

	private arborDialog: MatDialogRef<ConfirmDialog, unknown>;

	private destroy$ = new Subject<boolean>();

	protected readonly CompanyOfferType = CompanyOfferType;

	constructor(
		private navigationStore: AppLayoutStore,
		private sessionStore: SessionStore,
		private globalGetsStore: GlobalGetsStore,
		private confirmDialogService: ConfirmDialogService
	) {}

	ngOnInit(): void {
		this.user$ = this.sessionStore.user$;
		this.currentCompany$ = this.sessionStore.currentCompany$;
		this.assignedCompanies$ = this.sessionStore.assignedCompanies$;
		this.tabItems$ = this.navigationStore.tabItems$;
		this.globalGetsDone$ = this.globalGetsStore.globalGetsDone$;
		this.sessionStore.loadUserData();
		this.isAuthenticatedAndReady$ = this.sessionStore.isAuthenticatedAndReady$;
		this.sessionStore.beforeReload$.pipe(takeUntil(this.destroy$)).subscribe((reload) => {
			if (reload) {
				this.saveCurrentCompany();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	onCustomerSelectionChange(event: MatRadioChange) {
		this.sessionStore.changeCompanyId(event.value as number);
		this.saveCurrentCompany();
	}

	onLogout() {
		this.sessionStore.endSession();
	}

	openArborPortalModal() {
		const data: ConfirmDialogData = {
			title: 'Arbor Portal',
			message: 'Access to the Customer Arbor Portal will be available until the end of 2024 and then will be removed.',
			confirmActionText: 'Ok',
			cancelActionText: 'Cancel',
			closable: true,
		};
		const dialogConfig: MatDialogConfig = {
			width: '500px',
			disableClose: false,
		};

		this.arborDialog = this.confirmDialogService.openConfirmDialog(data, dialogConfig);

		this.subscribeToArborModalConfirm(this.arborDialog.componentInstance);
	}

	private saveCurrentCompany() {
		this.sessionStore.saveCurrentSettings('companyInfo', this.sessionStore.currentCompany);
	}

	private subscribeToArborModalConfirm(dialog: ConfirmDialog) {
		this.arborModalSub?.unsubscribe();

		this.arborModalSub = dialog.actionConfirmed.pipe(takeUntil(this.destroy$)).subscribe(() => {
			window.open(environment.ARBOR_PORTAL_URL, '_blank');
		});
	}
}

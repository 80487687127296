<div class="alert-wizard" aria-modal="true">
  <form [formGroup]="alertSearchWizardForm">
    <div mat-dialog-content>
      <div class="dialog-header">
        <h2 mat-dialog-title>Search Wizard</h2>
        <button tabIndex="-1" mat-icon-button [mat-dialog-close]="false">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="wizard-body">
        <section formGroupName="search">
          <div class="d-flex">
            <mat-form-field *ngIf="hideSearchString">
              <mat-label>Search string</mat-label>
              <input type="text" formControlName="searchString" matInput placeholder="Search" name="search" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Items per page</mat-label>
              <mat-select formControlName="alertItemsPerPage" data-cy="alert-search-items">
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="25">25</mat-option>
                <mat-option [value]="50">50</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
        
        <section formGroupName="severity">
          <!-- <h3 class="section-header">Severity Level</h3>
          <div class="alert-severity-section">
            <mat-checkbox
              class="level-checkbox"
              *ngFor="let s of alertSeverity; let i = index"
              [formControlName]="s.name"
              [checked]="convertToBooleanInput(s.value)"
            >
              {{ s.name }}
            </mat-checkbox>
          </div> -->

          <div class="d-flex">
            <mat-form-field class="flex-1">
              <mat-label>Alert Type</mat-label>
              <mat-select formControlName="alertType">
                <mat-option *ngFor="let alert of alertType" [value]="alert.value">
                  {{ alert.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Alert Classification</mat-label>
              <mat-select formControlName="alertClassification">
                <mat-option *ngFor="let class of alertClassification" [value]="class.value">
                  {{ class.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <section formGroupName="alertStatus">
          <h3 class="section-header">Display</h3>

          <ddos-ui-search-date-filter 
            [statusFormGroup]="alertSearchWizardForm.controls['alertStatus']"
            [statusStart]="statusStart"
            [statusEnd]="statusEnd"
            [minStartDate]="minAlertStartDate"
            [availableStatuses]="availableStatuses"
            [tooltipOngoingMsg]="tooltipOngoingMsg"
            [tooltipRecentMsg]="tooltipRecentMsg"
          ></ddos-ui-search-date-filter>

        </section>

        <section formGroupName="maxImpactAlertTraffic">
          <h3 class="section-header">Low/High Alert Traffic Thresholds</h3>
          <div class="min-max-units">
            <mat-form-field>
              <mat-label>Low</mat-label>
              <input type="number" matInput formControlName="lowValue" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>High</mat-label>
              <input type="number" matInput formControlName="highValue" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Units</mat-label>
              <mat-select formControlName="lowHighValueUnits">
                <mat-option *ngFor="let class of alertMaxTrafficImpact" [value]="class.value">
                  {{ class.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <section formGroupName="maxSeverityPercent">
          <h3 class="section-header">Low/High Severity % Thresholds</h3>

          <div class="min-max-units">
            <mat-form-field>
              <mat-label>Low</mat-label>
              <input type="number" matInput formControlName="lowValue" min="0" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>High</mat-label>
              <input type="number" matInput formControlName="highValue" min="0" />
            </mat-form-field>
          </div>
        </section>
      </div>

      <div mat-dialog-actions [align]="'end'">
        <button mat-button [mat-dialog-close]="false" color="primary">
          {{ data?.cancelActionText || "Cancel" }}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true" [disabled]="searchButtonDisabled" data-cy="alert-search-button">
          <mat-icon>search</mat-icon>
          {{ data?.confirmActionText || "Search" }}
        </button>
      </div>
    </div>
  </form>
</div>
/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
export type SidebarChild = {
	title: string;
	path: string;
	isActive?: boolean;
};

export type SidebarItem = SidebarChild & {
	icon?: string;
	parent: boolean;
	children: SidebarChild[];
};

export type TabItem = { title: string; path: string };

export enum AppLayoutRouting {
	HOME = 'home',
	DASHBOARD = 'dashboard',
	TRAFFIC = 'traffic',
	ZONES = 'zones',
	REPORTS = 'reports',
	ADMIN = 'admin',
	KNOWLEDGE = 'knowledge',
	ALERTS = 'alerts',
	SETTINGS = 'settings',
	ALERT_REPORT = 'alert-report',
	MITIGATIONS = 'mitigations',
	MITIGATION_REPORT = 'mitigation-report',
	MITIGATION_DETAILS = 'mitigation-details',
	IP_UNDER_PIM = `IP's Under PIM`,
	NON_PIM_CMO = `IP's Under child objects`,
	IP_MONITORED = 'monitor',
	BILLING_REPORT = 'billing-report',
	IP_BY_TYPE = 'ip-by-type',
	PIM = 'pim',
	NON_PIM = 'non-pim',
	MONITORED = 'MONITORED',
	USER_ERROR = 'user-error',
	BDID_ERROR = 'bdid-error',
}

export enum TrafficLayoutRouting {
	ANALYTICS = 'analytics',
	TRAFFIC = 'analytics',
	BYAPP = 'app',
	BYTCP = 'by-tcp',
	BYUDP = 'by-udp',
	BYTOPDEST = 'by-top-dest',
	KNOWLEDGE = 'knowledge',
	ALERTS = 'alerts',
	ALERT_SUMMARY = 'alert-summary',
	TRAFFIC_DETAILS = 'traffic-details',
	NOTES = 'notes',
	COMPANY_ID = 'companyID',
	MITIGATION_NAME = 'mitigationName',
	ALERT_ID = 'alertId',
}

export const TabsForReactiveUser: TabItem[] = [
	{ title: 'Home', path: `/${AppLayoutRouting.HOME}` },
	{ title: `Registered IP's`, path: `/${AppLayoutRouting.ZONES}` },
	{ title: 'Admin', path: `/${AppLayoutRouting.ADMIN}` },
	{ title: 'Reports', path: `/${AppLayoutRouting.REPORTS}/${AppLayoutRouting.MITIGATION_REPORT}` },
	// { title: 'Knowledge Base', path: `/${AppLayoutRouting.KNOWLEDGE}` },
];

export const TabsForRegularUser: TabItem[] = [
	{ title: 'Home', path: `/${AppLayoutRouting.HOME}` },
	{ title: 'Traffic Analytics', path: `/${AppLayoutRouting.TRAFFIC}` },
	{ title: `Protected Zones`, path: `/${AppLayoutRouting.ZONES}` },
	{ title: 'Admin', path: `/${AppLayoutRouting.ADMIN}` },
	{ title: 'Reports', path: `/${AppLayoutRouting.REPORTS}/${AppLayoutRouting.ALERT_REPORT}` },
	// { title: 'Knowledge Base', path: `/${AppLayoutRouting.KNOWLEDGE}` },
];

export const TrafficSidebarsForRegularUser: SidebarItem[] = [
	{
		title: 'Traffic',
		path: `/${AppLayoutRouting.TRAFFIC}/${TrafficLayoutRouting.TRAFFIC}`,
		parent: true,
		children: [
			{ title: 'Application', path: `/traffic/${TrafficLayoutRouting.BYAPP}` },
			{ title: 'UDP', path: `/traffic/${TrafficLayoutRouting.BYUDP}` },
			{ title: 'TCP', path: `/traffic/${TrafficLayoutRouting.BYTCP}` },
			{ title: 'Top Talker', path: `/traffic/${TrafficLayoutRouting.BYTOPDEST}` },
		],
	},
	{
		title: 'Alerts',
		path: `/traffic/${AppLayoutRouting.ALERTS}`,
		parent: false,
		children: [],
	},
	{
		title: 'Mitigations',
		path: `/traffic/${AppLayoutRouting.MITIGATIONS}`,
		parent: false,
		children: [],
	},
];

export const ReportSidebarsForRegularUser: SidebarItem[] = [
	{ title: 'Alerts', path: `/reports/${AppLayoutRouting.ALERT_REPORT}`, parent: false, children: [] },
	{ title: 'Mitigation', path: `/reports/${AppLayoutRouting.MITIGATION_REPORT}`, parent: false, children: [] },
	{
		title: AppLayoutRouting.IP_UNDER_PIM,
		path: `/reports/${AppLayoutRouting.PIM}`,
		parent: false,
		children: [],
	},
	{
		title: AppLayoutRouting.NON_PIM_CMO,
		path: `/reports/${AppLayoutRouting.NON_PIM}`,
		parent: false,
		children: [],
	},
	{ title: "IP's Monitored", path: `/reports/${AppLayoutRouting.IP_MONITORED}`, parent: false, children: [] },
	{ title: 'Billing Reports', path: `/reports/${AppLayoutRouting.BILLING_REPORT}`, parent: false, children: [] },
];

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, snackBarDuration, SNACKBAR_TYPE } from '@app/components/shared/snackbar/snackbar.component';

@Injectable({
	providedIn: 'root',
})
export class DdosErrorHandler implements ErrorHandler {
	constructor(
		private snackbar: MatSnackBar,
		private zone: NgZone
	) {}

	handleError(error: unknown) {
		if (error instanceof HttpErrorResponse) {
			if (error.error && (error.status === 500 || error.status === 0)) {
				this.showSnackbarMessage(error.error as string);
			}
		}
	}

	private showSnackbarMessage(msg: string) {
		this.zone.run(() => {
			return this.snackbar.openFromComponent(SnackbarComponent, {
				data: { message: msg, snackType: SNACKBAR_TYPE.ERROR },
				panelClass: 'snackbar',
				duration: snackBarDuration,
			});
		});
	}
}

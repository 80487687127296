/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import moment from 'moment';
import { NameValueOption } from '@src/app/stores/common.model';
import { BooleanInput } from '@angular/cdk/coercion';
import { WizardType, SearchWizardDialogData } from '../../services/dialog/dialog-factory.service';
import { Subject, debounceTime, distinctUntilChanged, pairwise, startWith, takeUntil } from 'rxjs';
import { SearchWizardMitigationService } from '../../services/search/search-wizard-mitigation.service';
import { isEqual } from 'lodash';
import { MitigationSearchForm, MitigationSearchFormValuesPartial } from '../../services/search/search.model';

@Component({
	selector: 'ddos-ui-search-wizard-mitigation',
	templateUrl: './search-wizard-mitigation.component.html',
	styleUrls: ['./search-wizard-mitigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchWizardMitigationComponent implements OnInit, OnDestroy {
	BooleanInput: BooleanInput;

	wizardType: WizardType;

	availableStatuses: NameValueOption[];

	statusStart: NameValueOption[];

	statusEnd: NameValueOption[];

	private destroy$ = new Subject<boolean>();

	mitigationSearchForm: FormGroup<MitigationSearchForm>;

	mitigationIpVersion: NameValueOption[];

	mitigationType: NameValueOption[];

	searchButtonDisabled: boolean = false;

	readonly tooltipRecentMsg: string = 'All closed mitigations dating back to the last 6 months';

	readonly tooltipOngoingMsg: string = 'Selecting "Ongoing" checkbox will disable the "End by" date selection option for "Ongoing" and "Recent" (if selected) mitigations.';

	hideSearchString: boolean = false;

	minMitigationStartDate: string;

	constructor(
		public dialogRef: MatDialogRef<SearchWizardMitigationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: SearchWizardDialogData,
		public wizardService: SearchWizardMitigationService,
	) {}

	ngOnInit(): void {
		this.mitigationSearchForm = this.data.context;
		this.configureMitigationWizardElements();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	configureMitigationWizardElements() {
		if (!this.mitigationSearchForm) {
			return;
		}

		this.initAvailableValues();

		this.mitigationSearchForm.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			startWith({}),
			pairwise(),
			takeUntil(this.destroy$)
		  ).subscribe(([prevForm, nextForm]) => {
			if (!isEqual(prevForm, nextForm)) {
				this.checkFormChanges(nextForm);
			}
		});
	}

	private initAvailableValues() {
		this.availableStatuses = this.wizardService.getStatus();
		this.statusStart = this.wizardService.getStatusByType('START');
		this.statusEnd = this.wizardService.getStatusByType('END');
		this.mitigationIpVersion = this.wizardService.getMitigationIpVersions();
		this.mitigationType = this.wizardService.getMitigationTypes();
		this.minMitigationStartDate = moment.utc().subtract(6, 'months').toString();
		this.hideSearchString = !!this.mitigationSearchForm.value.mitigationSearch.mitigationSearchString
	}

	private checkFormChanges(form: MitigationSearchFormValuesPartial) {
		if (!!form.mitigationStatus.startDate || !!form.mitigationStatus.endDate) {
			this.mitigationSearchForm.get('mitigationStatus').get('startDate').updateValueAndValidity();
			this.mitigationSearchForm.get('mitigationStatus').get('startTime').updateValueAndValidity();
			this.mitigationSearchForm.get('mitigationStatus').get('endDate').updateValueAndValidity();
			this.mitigationSearchForm.get('mitigationStatus').get('endTime').updateValueAndValidity();
		}

		this.searchButtonDisabled = !this.mitigationSearchForm.valid;
	}
}

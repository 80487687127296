/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Output, Renderer2 } from '@angular/core';

@Directive({
	selector: '[ddos-button-toggle-group]',
})
export class DdosToggleButtonDirective {
	@Output() value: EventEmitter<string> = new EventEmitter();

	activeElement: ElementRef<any>;

	@HostBinding('class.ddos-button-toggle-group')
	hostClass = true;

	constructor(
		private renderer: Renderer2,
		public el: ElementRef
	) {}

	ngAfterViewInit() {
		this.activeElement = this.el.nativeElement.querySelector('.active-button-toggle');
	}

	@HostListener('click', ['$event']) onClick(event) {
		if (event.target.localName === 'div') {
			return;
		}
		if (event.target.classList.contains('active-button-toggle')) {
			return;
		}

		this.activeElement = this.el.nativeElement.querySelector('.active-button-toggle');

		this.value.emit(event.target.innerHTML.toUpperCase().replaceAll(' ', '_'));
		this.renderer.addClass(event.target, 'active-button-toggle');
		this.renderer.removeClass(this.activeElement, 'active-button-toggle');
	}

	addClass(className: string, element: any) {
		this.renderer.addClass(element, className);
	}

	removeClass(className: string, element: any) {
		this.renderer.removeClass(element, className);
	}
}

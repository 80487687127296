<div class="mitigation-wizard" aria-modal="true">
  <form [formGroup]="mitigationSearchForm">
    <div mat-dialog-content>
      <div class="dialog-header">
        <h2 mat-dialog-title>Mitigation Wizard</h2>
        <button tabIndex="-1" mat-icon-button [mat-dialog-close]="false">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="wizard-body">
        <section formGroupName="mitigationSearch">

          <div class="d-flex">
            <mat-form-field *ngIf="hideSearchString">
              <mat-label>Search string</mat-label>
              <input type="text" formControlName="mitigationSearchString" matInput placeholder="Search" name="search" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Items per page</mat-label>
              <mat-select formControlName="mitigationItemsPerPage" data-cy="mitigation-search-items">
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="25">25</mat-option>
                <mat-option [value]="50">50</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <section formGroupName="mitigationStatus">
          <h3 class="section-header">Status</h3>

          <ddos-ui-search-date-filter 
            [statusFormGroup]="mitigationSearchForm.controls['mitigationStatus']"
            [statusStart]="statusStart"
            [statusEnd]="statusEnd"
            [minStartDate]="minMitigationStartDate"
            [availableStatuses]="availableStatuses"
            [tooltipOngoingMsg]="tooltipOngoingMsg"
            [tooltipRecentMsg]="tooltipRecentMsg"
          ></ddos-ui-search-date-filter>
        </section>

        <section formGroupName="ipVersion">
          <h3 class="section-header">IP Version</h3>

          <div>
            <mat-checkbox class="level-checkbox" formControlName="ipv4"> IP v4 </mat-checkbox>
            <mat-checkbox class="level-checkbox" formControlName="ipv6"> IP v6 </mat-checkbox>
          </div>
        </section>

        <section formGroupName="mitigationType">
          <h3 class="section-header">Mitigation Type</h3>

          <div>
            <mat-checkbox class="level-checkbox" formControlName="flowspec"> Flowspec </mat-checkbox>
            <mat-checkbox class="level-checkbox" formControlName="notFlowspec"> Non-Flowspec </mat-checkbox>
          </div>
        </section>
      </div>

      <div mat-dialog-actions [align]="'end'">
        <button mat-button [mat-dialog-close]="false" color="primary">
          {{ data?.cancelActionText || "Cancel" }}
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [mat-dialog-close]="true"
          [disabled]="searchButtonDisabled"
          data-cy="mitigation-search-button"
        >
          <mat-icon>search</mat-icon>
          {{ data?.confirmActionText || "Search" }}
        </button>
      </div>
    </div>
  </form>
</div>
/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import * as Bowser from 'bowser'; // TypeScript
import { Subscription } from 'rxjs';
import { SessionStore } from '@app/stores/session/session.store';
import { SnackbarComponent, snackBarDuration, SNACKBAR_TYPE } from '@app/components/shared/snackbar/snackbar.component';

@Component({
	selector: 'ddos-ui-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	RouterSubscription: Subscription;

	@HostListener('window:beforeunload', ['$event']) unloadHandler(_: Event) {
		this.sessionStore.ongoingReload();
	}

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private sessionStore: SessionStore
	) {
		this.RouterSubscription = router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.sessionStore.afterPageReload(!router.navigated);
			}
		});
		const errorText = this.detectBrowserValidity()
			? null
			: `Your browser is out of date and is not compatible with this site.
    Please use the latest version of Chrome, Firefox, or Safari`;

		if (errorText) {
			this.snackBar.openFromComponent(SnackbarComponent, {
				data: {
					message: errorText,
					snackType: SNACKBAR_TYPE.ERROR,
				},
				panelClass: 'snackbar',
				duration: snackBarDuration,
			});
		}
	}

	detectBrowserValidity() {
		const browser = Bowser.getParser(window.navigator.userAgent);
		return browser.satisfies({
			windows: {
				'internet explorer': '>15',
				'Microsoft Edge': '>80',
			},
			macos: {
				safari: '>13',
			},
			chrome: '>80',
			firefox: '>80',
			opera: '>80',
		});
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';
import { Alert, AlertKeyObject } from '@src/app/stores/alert/alert.model';
import { assertValue } from '@src/app/utils/assert';

@Pipe({
	name: 'alertToAlert',
})
export class AlertToAlertPipe implements PipeTransform {
	transform(alertKeyObject: AlertKeyObject, alertId: string): Alert {
		return assertValue(alertKeyObject.objects[alertId]);
	}
}

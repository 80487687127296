/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as URLS from '@src/app/stores/api.urls';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	filter,
	interval,
	mergeMap,
	Observable,
	of,
	startWith,
	Subscription,
	tap,
	throwError,
} from 'rxjs';
import moment from 'moment';
import 'moment-timezone';
import { SessionStore } from '@src/app/stores/session/session.store';
import { assertValue } from '@src/app/utils/assert';
import {
	ApiResponse,
	CompanyOfferType,
	PrimitiveValue,
	ReturnCode,
	ServiceType,
	updateDelay,
} from '@src/app/stores/common.model';
import { OrUndefineable, Undefineable } from '@src/types/types.utils';
import { cloneKeyObject } from '@src/app/utils/key-object';
import { BooleanInput } from '@angular/cdk/coercion';
import { SnackbarComponent, snackBarDuration, SNACKBAR_TYPE } from '@app/components/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	ConfirmDialogService,
	DDOS_CONFIRM_DIALOG_NO_DATA,
} from '@app/components/shared/services/dialog/confirm-dialog.service';
import { Location } from '@angular/common';
import { RequestDetails } from '@app/stores/admin/admin.model';
import { REQUEST_ID } from '@src/app/stores/api.urls';
import { AFTER_VALUE, BEFORE_VALUE } from '../../alert/alert.model';
import { addPropertiesToEachObject, isSucceededResponse } from '../../common.utils';
import {
	UpdateIpStatus,
	NetworkConnectionKeyObject,
	MitigationQueryParams,
	AddNetworkConnection,
	BulkUpdateNetworkConnection,
	MitigationRate,
	UpdateNetworkConnection,
	DeleteNetworkConnection,
	UpdateNetworkConnections,
	AddNewNetworkConnection,
	Mitigation,
	MitigationKeyObject,
	MitigationRateKeyObject,
	MitigationCollection,
	ActionResult,
	MitigationActionRequest,
	StartMitigationRequest,
	StopMitigationRequest,
	NetworkConnectionCollection,
	MitigationSearchQuery,
	CMOKeyObject,
} from './mitigation.model';
import {
	getMitigationStatusForNetworkAddress,
	getMitigationKeyObject,
	getUpdateNetworkConnections,
	getNetworkConnectionKeyObjects,
	getMitigationRate,
	getNonPIMCOMKeyObjects,
	getPIMCOMKeyObjects,
} from './mitigation.converter';

export const REACTIVE_USERS_IP_LIMIT = 10;

@Injectable({
	providedIn: 'root',
})
export class MitigationStore {
	// keyed by mitigation name.
	private readonly mitigationKeyObjectSub = new BehaviorSubject<MitigationKeyObject>(undefined);

	// keyed by mitigation rate name.
	private readonly mitigationRateKeyObjectSub = new BehaviorSubject<MitigationRateKeyObject>(undefined);

	// Used to apply filters once everytime it updates the mitigations
	private readonly mitigationsUpdateEvent = new BehaviorSubject<Undefineable<boolean>>(undefined);

	// each of NetworkConnectionKeyObject keyed by network address.
	private readonly _networkConnectionKeyObjectsSub = new BehaviorSubject<OrUndefineable<NetworkConnectionKeyObject[]>>(
		undefined
	);

	private readonly syncedStatusNetworkConnectionKeyObjectsSub = new BehaviorSubject<
		OrUndefineable<NetworkConnectionKeyObject[]>
	>(undefined);

	// each of PimCMOKeyObject was keyed by network address(pimCMOIp)
	private readonly pimCMOKeyObjectsSub = new BehaviorSubject<OrUndefineable<CMOKeyObject[]>>(undefined);

	private readonly cmoKeyObjectsSub = new BehaviorSubject<OrUndefineable<CMOKeyObject[]>>(undefined);

	private readonly ongoingMitigationsSub = new BehaviorSubject<OrUndefineable<UpdateIpStatus[]>>(undefined);

	private readonly canAddIpNetworkConnectionSub = new BehaviorSubject<boolean>(false);

	intervalMitigationSub: Subscription;

	constructor(
		private http: HttpClient,
		private sessionStore: SessionStore,
		private snackbar: MatSnackBar,
		private dialogService: ConfirmDialogService,
		private location: Location
	) {
		combineLatest([this.ongoingMitigations$, this._networkConnectionKeyObjects$])
			.pipe(filter((res) => !!res[0] && !!res[1]))
			.subscribe(([ongoingMitigations, networks]) => {
				if (ongoingMitigations && networks) {
					this.syncOngoingMitigationNetworkConnectionsStatus(ongoingMitigations);
				}
			});
	}

	get mitigationKeyObject$() {
		return this.mitigationKeyObjectSub.asObservable();
	}

	get mitigationKeyObject() {
		return this.mitigationKeyObjectSub.value;
	}

	get mitigationRateKeyObject$() {
		return this.mitigationRateKeyObjectSub.asObservable();
	}

	get mitigationRateKeyObject() {
		return this.mitigationRateKeyObjectSub.value;
	}

	get mitigationsUpdateEvent$() {
		return this.mitigationsUpdateEvent.asObservable();
	}

	get syncedStatusNetworkConnectionKeyObjects$() {
		return this.syncedStatusNetworkConnectionKeyObjectsSub.asObservable();
	}

	get syncedStatusNetworkConnectionKeyObjects() {
		return this.syncedStatusNetworkConnectionKeyObjectsSub.value;
	}

	private get _networkConnectionKeyObjects$() {
		return this._networkConnectionKeyObjectsSub.asObservable();
	}

	private get _networkConnectionKeyObjects() {
		return this._networkConnectionKeyObjectsSub.value;
	}

	get pimCMOKeyObjects$() {
		return this.pimCMOKeyObjectsSub.asObservable();
	}

	get pimCMOKeyObjects() {
		return this.pimCMOKeyObjectsSub.value;
	}

	get cmoKeyObjects$() {
		return this.cmoKeyObjectsSub.asObservable();
	}

	get cmoKeyObjects() {
		return this.cmoKeyObjectsSub.value;
	}

	get ongoingMitigations$() {
		return this.ongoingMitigationsSub.asObservable();
	}

	get ongoingMitigations() {
		return this.ongoingMitigationsSub.value;
	}

	get canAddIpNetworkConnection$() {
		return this.canAddIpNetworkConnectionSub.asObservable();
	}

	get canAddIpNetworkConnection() {
		return this.canAddIpNetworkConnectionSub.value;
	}

	getMitigations(refresh = true) {
		if (refresh || !this.mitigationKeyObject?.keys.length) {
			const getMitigationReportUrl = URLS.getApiUrl(
				URLS.MITIGATION_REPORT,
				assertValue(this.sessionStore.currentCompany.companyID)
			);

			// notify subscribers that it is reloading.
			this.mitigationKeyObjectSub.next(undefined);
			const request = this.http.get<ApiResponse<MitigationCollection>>(getMitigationReportUrl).pipe(
				catchError((e: Error) => {
					// to complete the global gets.
					this.mitigationKeyObjectSub.next(undefined);
					return throwError(() => e);
				}),
				tap((response) => {
					if (isSucceededResponse(response)) {
						const mitigationKeyObject: MitigationKeyObject = getMitigationKeyObject(response);
						// The use of addPropertiesToEachElement is emulating the addition of region and site properties, in the future this func is not needed
						mitigationKeyObject.objects = addPropertiesToEachObject<Mitigation>(mitigationKeyObject.objects, {
							region: this.sessionStore.currentCompanyDetail.region,
							site: this.sessionStore.currentCompanySite,
						});
						this.mitigationKeyObjectSub.next(mitigationKeyObject);
						this.mitigationsUpdateEvent.next(true);
					}
				})
			);

			if (this.intervalMitigationSub) {
				this.intervalMitigationSub.unsubscribe();
			}
			this.intervalMitigationSub = interval(updateDelay)
				.pipe(
					startWith(0),
					mergeMap(() => request)
				)
				.subscribe();
		}
	}

	getMitigationByPeriod(startTime: string, endTime: string): Mitigation[] {
		const utcStartTime = moment.utc(startTime).format('MM/DD/YYYY');
		const utcEndTime = moment.utc(endTime).format('MM/DD/YYYY');
		return this.getKeysInTimeRangeForMitigations(utcStartTime, utcEndTime);
	}

	private getKeysInTimeRangeForMitigations(startTime: string, endTime: string): Mitigation[] {
		return this.mitigationKeyObject.keys
			.map((k) => this.mitigationKeyObject.objects[k])
			.filter((mitigation) => {
				return (
					(mitigation.attackStartDate &&
						moment
							.utc(mitigation.attackStartDate)
							.isBetween(moment.utc(startTime), moment.utc(endTime).add(1, 'day'))) ||
					(mitigation.attackEndDate &&
						moment.utc(mitigation.attackEndDate).isBetween(moment.utc(startTime), moment.utc(endTime).add(1, 'day')))
				);
			});
	}

	getMitigationRate(params: MitigationQueryParams, refresh = true) {
		if (refresh || !this.mitigationRateKeyObject.objects[params.name]) {
			const startDate = moment.utc(params.startTime).format();
			const endDate = params.endTime && moment(params.endTime).isValid() ? moment.utc(params.endTime).format() : '';

			const getMitigationDetailReportUrl = URLS.getApiUrl(
				URLS.MITIGATION_SUMMARY_REPORT,
				assertValue(this.sessionStore.currentCompany.companyID)
			)
				.replace('{startTime}', `startTime=${encodeURIComponent(startDate)}`)
				.replace('{endTime}', `endTime=${encodeURIComponent(endDate)}`)
				.replace('{mitigationName}', `mitigationName=${params.name}`);

			this.http
				.get<ApiResponse<MitigationRate>>(getMitigationDetailReportUrl)
				.pipe(
					tap((response) => {
						if (response.dataObject) {
							const mitigationRate = getMitigationRate(response);
							this.mitigationRateKeyObjectSub.next({
								...(this.mitigationRateKeyObject ?? undefined),
								objects: {
									...this.mitigationRateKeyObject?.objects,
									[`${params.name}`]: mitigationRate,
								},
							});
						} else {
							this.mitigationRateKeyObjectSub.next({
								objects: {},
								keys: [],
								snapshotKeys: [],
							});
						}
					}),
					catchError((err: HttpErrorResponse) => {
						if (err.status === 404) {
							const dialog = this.dialogService.openConfirmDialog(DDOS_CONFIRM_DIALOG_NO_DATA);
							dialog.afterClosed().subscribe((goBack) => {
								if (goBack) {
									this.location.back();
								}
							});
						}
						return throwError(() => err);
					})
				)
				.subscribe();
		}
	}

	getOngoingMitigations() {
		const checkOngoingMitigationsUrl = URLS.getApiUrl(
			URLS.ONGOING_MITIGATION_REPORT,
			assertValue(this.sessionStore.currentCompany.companyID)
		);

		this.ongoingMitigationsSub.next(undefined);
		this.http
			.get<ApiResponse<MitigationCollection>>(checkOngoingMitigationsUrl)
			.pipe(
				tap((response) => {
					if (isSucceededResponse(response)) {
						const updatedIps = response.dataObject.mitigationList.map((mitigation) =>
							getMitigationStatusForNetworkAddress(mitigation)
						);
						this.ongoingMitigationsSub.next(updatedIps);
					} else {
						this.ongoingMitigationsSub.next([]);
					}
				}),
				catchError((_) => {
					this.ongoingMitigationsSub.next([]);
					return of(null);
				})
			)
			.subscribe();
	}

	getSyncedMitigationStatus() {
		this.getNetworkConnections();
		this.getOngoingMitigations();
	}

	private requestMitigationAction(request: MitigationActionRequest) {
		const mitigationActionUrl = URLS.getApiUrl(
			URLS.MITIGATION_BY_ACTION,
			assertValue(this.sessionStore.currentCompany.companyID)
		);

		return this.http.post<ApiResponse<ActionResult>>(mitigationActionUrl, request).pipe(
			tap((response) => {
				if (isSucceededResponse(response)) {
					const ongoingMitigations: UpdateIpStatus[] = [
						{
							networkAddress: request.ipAddressList[0].ipAddress,
							mitigationPending: true,
							mitigationReady: true,
							action: request.action === 'start' ? 'Start Mitigation' : 'Stop Mitigation',
						},
					];
					this.syncOngoingMitigationNetworkConnectionsStatus(ongoingMitigations);
				}
			})
		);
	}

	private syncOngoingMitigationNetworkConnectionsStatus(updateIpsStatus: UpdateIpStatus[]) {
		updateIpsStatus.forEach((updatedIp) => {
			const { networkAddress, ...status } = updatedIp;
			const found = this.findNetworkConnectionByAddress(networkAddress);
			if (found) {
				found.objects[networkAddress] = {
					...found.objects[networkAddress],
					...status,
				};
			}
		});

		const cloned = [...this._networkConnectionKeyObjects];
		this.syncedStatusNetworkConnectionKeyObjectsSub.next(cloned);
	}

	start(request: StartMitigationRequest) {
		return this.requestMitigationAction(request);
	}

	stop(request: StopMitigationRequest) {
		return this.requestMitigationAction(request);
	}

	getNetworkConnections(refresh = true) {
		if (refresh || !this.syncedStatusNetworkConnectionKeyObjects) {
			const getNetworkConnectionsUrl = URLS.getApiUrl(
				URLS.GET_NETWORK_CONNECTIONS,
				assertValue(this.sessionStore.currentCompany.companyID)
			);

			this._networkConnectionKeyObjectsSub.next(undefined);
			this.http
				.get<ApiResponse<NetworkConnectionCollection>>(getNetworkConnectionsUrl)
				.pipe(
					tap((response) => {
						if (isSucceededResponse(response)) {
							const networkConnectionKeyObjects = getNetworkConnectionKeyObjects(response);
							const pimCMOKeyObjects: CMOKeyObject[] = getPIMCOMKeyObjects(response);
							const cmoKeyObjects: CMOKeyObject[] = getNonPIMCOMKeyObjects(response);
							this.pimCMOKeyObjectsSub.next(pimCMOKeyObjects);
							this.cmoKeyObjectsSub.next(cmoKeyObjects);
							this._networkConnectionKeyObjectsSub.next(networkConnectionKeyObjects);
							this.canAddIpNetworkConnectionSub.next(this.checkEnableCanAddIpZone());
						} else {
							this._networkConnectionKeyObjectsSub.next([]);
						}
					}),
					catchError((err: HttpErrorResponse) => {
						this._networkConnectionKeyObjectsSub.next([]);
						return throwError(() => err);
					})
				)
				.subscribe();
		}
	}

	addNewNetworkConnection(request: AddNewNetworkConnection): Observable<ApiResponse<ActionResult>> {
		const networkConnection = this.syncedStatusNetworkConnectionKeyObjects.find(
			(n) => n.serviceType === ServiceType.RL
		);
		assertValue(networkConnection, 'This user cannot get to this feature');
		const addNetworkConnection: AddNetworkConnection = {
			companyID: this.sessionStore.currentCompanyDetail.companyID,
			companyName: this.sessionStore.currentCompanyDetail.companyName,
			defaultZoneName: this.sessionStore.currentCompanyDetail.defaultZoneName,
			circuitTier: networkConnection.circuitTier,
			wanIPAddress: networkConnection.wanIPAddress,
			serviceType: networkConnection.serviceType,
			siteNameSSPP: networkConnection.siteNameSSPP,
			siteID: request.siteID,
			netAddressDetailList: [
				{
					networkAddress: assertValue(request.networkAddress),
				},
			],
		};

		const addNetworkConnectionUrl = URLS.getApiUrl(
			URLS.ADD_NETWORK_CONNECTIONS,
			assertValue(this.sessionStore.currentCompany.companyID)
		);
		return this.http.post<ApiResponse<ActionResult>>(addNetworkConnectionUrl, addNetworkConnection);
	}

	addNetworkAddressToExistingNetworkConnection(request: AddNewNetworkConnection) {
		const networkConnection = this.syncedStatusNetworkConnectionKeyObjects.find(
			(n) => n.serviceType === ServiceType.RL
		);
		const newAddress = assertValue(request.networkAddress);
		// Allow reactive user to add to the existing.
		// Skip if the network address is already in the list.
		// Skip if the number of network address is smaller than 10.
		if (
			networkConnection &&
			!networkConnection.objects[newAddress] &&
			networkConnection.keys.length < REACTIVE_USERS_IP_LIMIT
		) {
			const cloned = cloneKeyObject(networkConnection);
			const updateReq = getUpdateNetworkConnections(cloned as NetworkConnectionKeyObject);
			updateReq.netAddressDetailList.push({
				networkAddress: newAddress,
				ipType: '',
				mitigationPending: false,
				mitigationReady: false,
				name: '',
				arecordAssignmentIP: newAddress,
			});

			return this.updateNetworkConnections([updateReq]);
		}

		const response: ApiResponse<ActionResult> = {
			returnCode: ReturnCode.ERROR,
			returnDescription: `This network address (${newAddress}) already exists in the network with id(${networkConnection.netConnectionID})`,
			serviceName: '',
		};
		return of(response);
	}

	deleteNetworkConnection(request: DeleteNetworkConnection): Observable<ApiResponse<ActionResult>> {
		const networkConnection = this.findNetworkConnectionByAddress(request.networkAddress);
		if (!networkConnection) {
			const response: ApiResponse<ActionResult> = {
				returnCode: ReturnCode.NOT_FOUND,
				returnDescription: `Cannot find the ip: ${request.networkAddress} on the network id ${request.netConnectionID}`,
				serviceName: '',
			};
			return of(response);
		}

		const { snapshotKeys } = networkConnection;
		const removedKeys = snapshotKeys.filter((address) => request.networkAddress !== address);

		// remove the matched network address.
		const updateReq = getUpdateNetworkConnections({
			...networkConnection,
			snapshotKeys: removedKeys,
		});
		return this.updateNetworkConnections([updateReq]);
	}

	updateNetworkConnection(request: UpdateNetworkConnection): Observable<ApiResponse<ActionResult>> {
		const networkConnection: Undefineable<NetworkConnectionKeyObject> = this.findNetworkConnectionByAddress(
			request.networkAddress
		);
		if (!networkConnection?.objects[request.networkAddress]) {
			const response: ApiResponse<ActionResult> = {
				returnCode: ReturnCode.NOT_FOUND,
				returnDescription: `Cannot find the ip: ${request.networkAddress} on the network id ${request.netConnectionID}`,
				serviceName: '',
			};
			return of(response);
		}

		// replace the matched address with the new address.
		networkConnection.objects[request.networkAddress] = {
			...networkConnection.objects[request.networkAddress],
			networkAddress: request.updateNetworkAddress,
		};
		const updateReq = getUpdateNetworkConnections(networkConnection);
		return this.updateNetworkConnections([updateReq]);
	}

	updateNetworkConnections(
		updateNetworkConnections: UpdateNetworkConnections[]
	): Observable<ApiResponse<ActionResult>> {
		const bulkUpdateNetworkConnectionUrl = URLS.getApiUrl(
			URLS.BULK_UPDATE_NETWORK_CONNECTIONS,
			assertValue(this.sessionStore.currentCompany.companyID)
		);

		const bulkUpdateConnectionsReq: BulkUpdateNetworkConnection = {
			networkConnectionList: updateNetworkConnections,
		};
		return this.http.put<ApiResponse<ActionResult>>(bulkUpdateNetworkConnectionUrl, bulkUpdateConnectionsReq);
	}

	findNetworkConnectionByAddress(networkAddress: string): OrUndefineable<NetworkConnectionKeyObject> {
		return this._networkConnectionKeyObjects.find((zone) => zone.objects[networkAddress]);
	}

	private checkEnableCanAddIpZone = () => {
		if (this.sessionStore.currentCompanyDetail.OfferType === CompanyOfferType.NON_REACTIVE) {
			return true;
		}

		return (
			this.syncedStatusNetworkConnectionKeyObjects
				?.filter((c) => c.serviceType === ServiceType.RL)
				.filter((d) => d.keys.length < REACTIVE_USERS_IP_LIMIT).length > 0
		);
	};

	private createWarningActionResponse(description: string): ApiResponse<ActionResult> {
		const response: ApiResponse<ActionResult> = {
			returnCode: ReturnCode.NOT_FOUND,
			returnDescription: description,
			serviceName: '',
		};

		return response;
	}

	resetMitigationSnapshot() {
		this.mitigationKeyObjectSub.next({
			...this.mitigationKeyObject,
			snapshotKeys: [...this.mitigationKeyObject.keys],
		});
	}

	filterMitigation(filterQuery: MitigationSearchQuery) {
		if (!this.mitigationKeyObject) {
			return;
		}
		let snapshotKeys: string[] = [...this.mitigationKeyObject.keys];
		snapshotKeys = this.filterOnStatus(filterQuery, snapshotKeys);
		snapshotKeys = this.filterOnBeforeOrAfterStartAndEndTime(filterQuery, snapshotKeys);
		snapshotKeys = this.filterOnIpVersion(filterQuery, snapshotKeys);
		snapshotKeys = this.filterOnType(filterQuery, snapshotKeys);

		this.mitigationKeyObjectSub.next({
			...this.mitigationKeyObject,
			snapshotKeys,
		});
	}

	private filterOnIpVersion(filterQuery: MitigationSearchQuery, keys: string[]) {
		const filterIpv4 = filterQuery.ipv4.value;
		const filterIpv6 = filterQuery.ipv6.value;
		const { objects } = this.mitigationKeyObject;
		let currentSnapshotKeys = [...keys];

		if (!filterIpv4 && filterIpv6) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => objects[k].networkAddress.includes(':'));
		} else if (filterIpv4 && !filterIpv6) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => !objects[k].networkAddress.includes(':'));
		}

		return currentSnapshotKeys;
	}

	protected convertToBooleanInput(value: PrimitiveValue): BooleanInput {
		return value as BooleanInput;
	}

	private filterOnType(filterQuery: MitigationSearchQuery, keys: string[]) {
		const filterFlowspec = filterQuery.flowspec.value;
		const filterNotFlowspec = filterQuery.notFlowspec.value;
		const { objects } = this.mitigationKeyObject;
		let currentSnapshotKeys = [...keys];

		if (!filterFlowspec && filterNotFlowspec) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => !objects[k].flowspec);
		} else if (filterFlowspec && !filterNotFlowspec) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => objects[k].flowspec);
		}

		return currentSnapshotKeys;
	}

	private filterOnStatus(filterQuery: MitigationSearchQuery, keys: string[]) {
		const onGoing = filterQuery.onGoing.value;
		const recent = filterQuery.recent.value;
		const { objects } = this.mitigationKeyObject;
		let currentSnapshotKeys = [...keys];
		if (onGoing && !recent) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => !objects[k].attackEndDate);
		}

		if (!onGoing && recent) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => !!objects[k].attackEndDate);
		}
		return currentSnapshotKeys;
	}

	private filterOnBeforeOrAfterStartAndEndTime(filterQuery: MitigationSearchQuery, keys: string[]) {
		const filterStartBeforeOrAfter = filterQuery.startBeforeOrAfter.value;
		const filterEndBeforeOrAfter = filterQuery.endBeforeOrAfter.value;
		const filterStartDate = filterQuery.startDate?.value && filterQuery.startDate.value.toString();
		const filterEndDate = filterQuery.endDate?.value && filterQuery.endDate.value.toString();
		const filterStartTime = filterQuery.startTime?.value && filterQuery.startTime.toString();
		const filterEndTime = filterQuery.endTime?.value && filterQuery.endTime.toString();
		const currentSnapshotKeys = [...keys];
		const { objects } = this.mitigationKeyObject;
		const dateformat = 'YYYY-MM-DD HH:mm:ss';
		if (filterStartDate && filterEndDate) {
			return currentSnapshotKeys.filter((k) => {
				const mitigation = objects[k];
				const mitigationStartFormatted = mitigation.attackStartDate
					? moment.utc(mitigation.attackStartDate).format(dateformat)
					: undefined;
				const mitigationEndFormatted = mitigation.attackEndDate
					? moment.utc(mitigation.attackEndDate).format(dateformat)
					: undefined;

				const startTime = moment.utc(filterStartTime, 'HH:mm:ss').toObject();
				const endTime = moment.utc(filterEndTime, 'HH:mm:ss').toObject();
				const filterStartFormatted = moment
					.utc(filterStartDate)
					.add({ hours: startTime.hours, minutes: startTime.minutes, seconds: startTime.seconds })
					.format(dateformat);
				const filterEndFormatted = moment
					.utc(filterEndDate)
					.add({ hours: endTime.hours, minutes: endTime.minutes, seconds: endTime.seconds })
					.format(dateformat);

				if (filterStartBeforeOrAfter === BEFORE_VALUE && filterEndBeforeOrAfter === AFTER_VALUE) {
					return mitigationStartFormatted < filterStartFormatted && mitigationEndFormatted >= filterEndFormatted;
				}
				if (filterStartBeforeOrAfter === AFTER_VALUE && filterEndBeforeOrAfter === BEFORE_VALUE) {
					return mitigationStartFormatted >= filterStartFormatted && mitigationEndFormatted < filterEndFormatted;
				}
				if (filterStartBeforeOrAfter === AFTER_VALUE && filterEndBeforeOrAfter === AFTER_VALUE) {
					return mitigationStartFormatted >= filterStartFormatted && mitigationEndFormatted >= filterEndFormatted;
				}
				if (filterStartBeforeOrAfter === BEFORE_VALUE && filterEndBeforeOrAfter === BEFORE_VALUE) {
					return mitigationStartFormatted < filterStartFormatted && mitigationEndFormatted < filterEndFormatted;
				}
				return false;
			});
		}

		if (filterStartDate) {
			return currentSnapshotKeys.filter((k) => {
				const mitigation = objects[k];
				const mitigationStartFormatted = mitigation.attackStartDate
					? moment.utc(mitigation.attackStartDate).format(dateformat)
					: undefined;

				const startTime = moment.utc(filterStartTime, 'HH:mm:ss').toObject();
				const filterStartFormatted = moment
					.utc(filterStartDate)
					.add({ hours: startTime.hours, minutes: startTime.minutes, seconds: startTime.seconds })
					.format(dateformat);

				if (mitigationStartFormatted && filterStartBeforeOrAfter === BEFORE_VALUE) {
					return mitigationStartFormatted < filterStartFormatted;
				}

				if (mitigationStartFormatted && filterStartBeforeOrAfter === AFTER_VALUE) {
					return mitigationStartFormatted >= filterStartFormatted;
				}

				return false;
			});
		}

		if (filterEndDate) {
			return currentSnapshotKeys.filter((k) => {
				const mitigation = objects[k];
				const mitigationEndFormatted = mitigation.attackEndDate
					? moment.utc(mitigation.attackEndDate).format(dateformat)
					: undefined;

				const endTime = moment.utc(filterEndTime, 'HH:mm:ss').toObject();
				const filterEndFormatted = moment
					.utc(filterEndDate)
					.add({ hours: endTime.hours, minutes: endTime.minutes, seconds: endTime.seconds })
					.format(dateformat);

				if (filterEndBeforeOrAfter === AFTER_VALUE) {
					return mitigationEndFormatted >= filterEndFormatted;
				}
				if (filterEndBeforeOrAfter === BEFORE_VALUE) {
					return mitigationEndFormatted < filterEndFormatted;
				}

				return false;
			});
		}

		return currentSnapshotKeys;
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';

// MATERIAL IMPORTS

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';

import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DdosToggleButtonDirective } from './directives/ddos-toggle-button.directive';
import { DdosRadioButtonDirective } from './directives/ddos-radio-button.directive';
import { ConfirmDialog } from './confirm-dialog/confirm.component';
import { DdosListComponent } from './ddos-list/ddos-list.component';
import { DialogComponent } from './dialog/dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PropertyGetterPipe } from './pipes/data-property-getter.pipe';
import { InnerHtmlPipe } from './pipes/inner-html.pipe';
import { SearchWizardAlertComponent } from './search-wizard/search-wizard-alert/search-wizard-alert.component';
import { SearchWizardMitigationComponent } from './search-wizard/search-wizard-mitigation/search-wizard-mitigation.component';
import { ShellComponent } from './shell/shell.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DdosTableTableComponent } from './table/table.component';
import { TimerComponent } from './timer/timer.component';
import { AlertToAlertPipe } from './pipes/alert-ko-alert.pipe';
import { AlertToAlertsPipe } from './pipes/alert-ko-alerts.pipe';
import { SearchDateFilterComponent } from './search-wizard/common/search-date-filter/search-date-filter.component';
import { DurationPipe } from './pipes/duration.pipe';
import { BdidNotFoundComponent } from './bdid-not-found/bdid-not-found.component';
import { SnackbarComponent } from './snackbar/snackbar.component';

const COMPONENTS = [
	SidebarComponent,
	ShellComponent,
	DdosToggleButtonDirective,
	DdosRadioButtonDirective,
	TimerComponent,
	SearchWizardAlertComponent,
	SearchWizardMitigationComponent,
	SearchDateFilterComponent,
	DdosTableTableComponent,
	LoaderComponent,
	DdosListComponent,
	PageNotFoundComponent,
	BdidNotFoundComponent,
];

const DIALOGS = [ConfirmDialog, DialogComponent, SnackbarComponent];

const PIPES = [PropertyGetterPipe, InnerHtmlPipe, AlertToAlertPipe, AlertToAlertsPipe, DurationPipe];
const MODULES = [
	CommonModule,
	MatToolbarModule,
	MatIconModule,
	LayoutModule,
	MatButtonModule,
	MatSidenavModule,
	MatListModule,
	MatMenuModule,
	MatIconModule,
	MatCardModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatInputModule,
	MatSnackBarModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule,
	MatButtonToggleModule,
	MatCheckboxModule,
	MatSelectModule,
	MatDividerModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatTabsModule,
	MatTableModule,
	MatOptionModule,
	MatPaginatorModule,
	MatSortModule,
	MatChipsModule,
	ScrollingModule,
	MatDialogModule,
	MatTooltipModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatRadioModule,
	NgxMatTimepickerModule,
];

@NgModule({
	declarations: [...COMPONENTS, ...DIALOGS, ...PIPES],
	imports: [...MODULES],
	entryComponents: [...DIALOGS],
	providers: [DatePipe, TitleCasePipe],
	exports: [...COMPONENTS, ...MODULES, ...PIPES],
})
export class SharedModule {}

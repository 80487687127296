/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
	TA_DROPDOWN_ITEMS_PER_PAGE,
	TA_WIZARD_ITEMS_PER_PAGE,
	TA_DROPDOWN_STATUS_START,
	TA_DROPDOWN_STATUS_END,
	TA_DROPDOWN_MITIGATION_TYPE,
	TA_DROPDOWN_MITIGATION_IP_VERSION,
} from '@src/app/stores/report/traffic/traffic.model';
import { NameValueOption } from '@src/app/stores/common.model';
import { MitigationSearchForm, MitigationSearchFormValuesPartial, SearchWizardStatusType } from './search.model';
import { MitigationSearchQuery } from '@src/app/stores/report/mitigation/mitigation.model';

@Injectable({
	providedIn: 'root',
})
export class SearchWizardMitigationService {

	private mitigationMinStartDate: string;

	getItemsPerPage(): number {
		return TA_WIZARD_ITEMS_PER_PAGE;
	}

	getStatus(): NameValueOption[] {
		return [
			{ name: 'ongoing', value: false },
			{ name: 'recent', value: false },
		];
	}

	getStatusByType(type: SearchWizardStatusType): NameValueOption[] {
		return type === 'START' ? [...TA_DROPDOWN_STATUS_START] : [...TA_DROPDOWN_STATUS_END];
	}

	getMitigationTypes(): NameValueOption[] {
		return [...TA_DROPDOWN_MITIGATION_TYPE];
	}

	getMitigationIpVersions(): NameValueOption[] {
		return [...TA_DROPDOWN_MITIGATION_IP_VERSION];
	}

	setMinMitigationStartDate(startTime: string) {
		this.mitigationMinStartDate = startTime;
	}

	getMinMitigationStartDate() {
		return this.mitigationMinStartDate;
	}

	generateMitigationSearchWizardForm(): FormGroup<MitigationSearchForm> {
		return new FormGroup<MitigationSearchForm>({
			mitigationSearch: new FormGroup({
				mitigationSearchString: new FormControl<string>(''),
				mitigationItemsPerPage: new FormControl(this.getItemsPerPage()),
			}),
			mitigationStatus: new FormGroup({
				ongoing: new FormControl<boolean>(true),
				recent: new FormControl<boolean>(true),
				isStart: new FormControl({ value: true, disabled: false }),
				isEnd: new FormControl({ value: false, disabled: true }),
				start: new FormControl('after'),
				end: new FormControl({ value: 'before', disabled: true }),
				startDate: new FormControl(''),
				endDate: new FormControl({ value: '', disabled: true }),
				startTime: new FormControl(''),
				endTime: new FormControl({ value: '', disabled: true }),
			}),
			ipVersion: new FormGroup({
				ipv4: new FormControl<boolean>(false),
				ipv6: new FormControl<boolean>(false),
			}),
			mitigationType: new FormGroup({
				flowspec: new FormControl<boolean>(false),
				notFlowspec: new FormControl<boolean>(false),
			}),
		});
	}

	getSearchQuery(search: MitigationSearchFormValuesPartial): Partial<MitigationSearchQuery> {
		return {
			mitigationSearchString: { value: search.mitigationSearch?.mitigationSearchString },
			mitigationItemsPerPage: { value: search.mitigationSearch?.mitigationItemsPerPage },
			onGoing: { value: search.mitigationStatus?.ongoing },
			recent: { value: search.mitigationStatus?.recent },
			startBeforeOrAfter: { value: search.mitigationStatus?.isStart && search.mitigationStatus?.start },
			endBeforeOrAfter: { value: search.mitigationStatus?.isEnd && search.mitigationStatus?.end },
			startDate: { value: search.mitigationStatus?.isStart && search.mitigationStatus?.startDate },
			endDate: { value: search.mitigationStatus?.isEnd && search.mitigationStatus?.endDate },
			startTime: { value: search.mitigationStatus?.isStart && search.mitigationStatus?.startTime },
			endTime: { value: search.mitigationStatus?.isEnd && search.mitigationStatus?.endTime },
			ipv4: { value: search.ipVersion?.ipv4 },
			ipv6: { value: search.ipVersion?.ipv6 },
			flowspec: { value: search.mitigationType?.flowspec },
			notFlowspec: { value: search.mitigationType?.notFlowspec },
		};
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export enum SNACKBAR_TYPE {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
}

export type SnackbarData = {
	snackType: SNACKBAR_TYPE;
	message: string;
};

export const snackBarDuration = 6000;

@Component({
	selector: 'ddos-ui-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
		private snackRef: MatSnackBarRef<SnackbarComponent>
	) {}

	get getIcon(): string {
		switch (this.data.snackType) {
			case SNACKBAR_TYPE.SUCCESS:
				return 'check_circle';
			case SNACKBAR_TYPE.ERROR:
				return 'error_outline';
			default:
				return 'info_outline';
		}
	}

	get getClass(): string {
		return `snackbar-${this.data.snackType}`;
	}

	close(): void {
		this.snackRef.dismiss();
	}
}

import { CompanyIdentifier } from '../common.model';

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
export const TOKEN_KEY = 'halo-access-token';
export const RELOADED = 'reloaded';

export type CompanyInfo = CompanyIdentifier & {
	customerGuidSSPP: string;
	orderGuidSSPP: string;
	offerType: number;
	ipbb: boolean;
	bdId: string;
	mitigationType: 'Pre-Auth' | 'MANUAL';
};

export type Userinfo = {
	email: string;
	userGroups: string;
	phone_number: string;
	postalCode: string;
	auth_time: string;
	given_name: string;
	edgeportal_roles: string;
	family_name: string;
};

export type CustomerInfo = {
	isAdmin: boolean;
	customerList: CompanyInfo[];
	userinfo: Userinfo;
};

export type ZoneData = {
	ipAddress: string;
	cidr: string;
	networkType: string;
	agnosticType: string;
	arecordAssignmentIP: string;
};

export type SsppData = {
	orderGuidSSPP: string;
	siteNameSSPP: string;
};

export type GpsData = {
	socCode: string;
	siteID: string;
	assetName: string;
};

export type CloudSignalingData = {
	cloudSignalingInstances: number;
	premiseDeviceList: Record<string, string>;
	attManagedCloudSignalingInstances: number;
	attManagedInstances: number;
	cloudSignalingPremiseDeviceList: unknown[] | undefined;
};

export type CompanyDetail = CompanyIdentifier & {
	defaultZoneName: string;
	customerGuidSSPP: string;
	OfferType: number;
	orderGuidSSPP: string;
	turnUpDate: string;
	detectionOnly: boolean;
	usageBilling: boolean;
	region: string;
	agnostic: boolean;
	ssppDataList: SsppData[] | undefined;
	zoneDefinitionList: ZoneData[] | undefined;
	gpsData: GpsData;
	model: number;
	distinctEndClientType: number;
	resellerList: SsppData;
	cloudSignalingData: CloudSignalingData;
	ipbb: boolean;
};

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as URLS from '@src/app/stores/api.urls';
import { BehaviorSubject, Subscription, catchError, interval, mergeMap, startWith, tap, throwError } from 'rxjs';
import { assertValue } from '@src/app/utils/assert';
import { SessionStore } from '@src/app/stores/session/session.store';
import { Sort } from '@angular/material/sort';
import { Undefineable } from '@src/types/types.utils';
import { ApiResponse, updateDelay } from '../common.model';
import {
	AlertKeyObject,
	AlertDetailKeyObject,
	AlertStatusFilter,
	AlertSearchQuery,
	Alert,
	BEFORE_VALUE,
	AFTER_VALUE,
	BPS_VALUE,
	ALL_VALUE,
	AlertCollection,
	AlertDetailQueryParam,
	AlertDetailCollection,
	BYTESPS_VALUE,
	AlertStatusEnum,
} from './alert.model';
import { getAlertKeyObject } from './alert.converter';
import { addPropertiesToEachObject, isSucceededResponse } from '../common.utils';
import moment from 'moment/moment';
import { AlertReportsDataSource } from '@app/stores/report/billing/billing.model';
import { getAlertReport } from '@app/stores/report/billing/billing.converter';
import { ConfirmDialogService, DDOS_CONFIRM_DIALOG_NO_DATA } from '@app/components/shared/services/dialog/confirm-dialog.service';
import { Location } from '@angular/common';
export const MISSING_ROUTE_DATA = 'missing {data} on the current route';

@Injectable({
	providedIn: 'root',
})
export class AlertStore {
	private readonly alertKeyObjectSub = new BehaviorSubject<Undefineable<AlertKeyObject>>(undefined);

	private readonly alertDetailKeyObjectSub = new BehaviorSubject<Undefineable<AlertDetailKeyObject>>(undefined);

	private readonly alertsUpdateEvent = new BehaviorSubject<Undefineable<boolean>>(undefined);

	private readonly defaultZoneNameSub = new BehaviorSubject<string>(undefined);

	intervalAlertsSub: Subscription;

	constructor(
		private http: HttpClient,
		private sessionStore: SessionStore,
		private dialogService: ConfirmDialogService,
		private location: Location
	) { }

	get alertKeyObject$() {
		return this.alertKeyObjectSub.asObservable();
	}

	get alertKeyObject() {
		return this.alertKeyObjectSub.value;
	}

	get alertDetailKeyObject$() {
		return this.alertDetailKeyObjectSub.asObservable();
	}

	get alertDetailKeyObject() {
		return this.alertDetailKeyObjectSub.value;
	}

	get alertsUpdateEvent$() {
		return this.alertsUpdateEvent.asObservable();
	}

	get defaultZoneName$() {
		return this.defaultZoneNameSub.asObservable();
	}

	get defaultZoneName() {
		return this.defaultZoneNameSub.value;
	}

	changeAlertStatusFilter(alertStatusFilter: AlertStatusFilter) {
		if (!this.alertKeyObject) {
			return;
		}
		if (alertStatusFilter === AlertStatusEnum.ALL) {
			this.alertKeyObjectSub.next({
				...this.alertKeyObject,
				snapshotKeys: [...this.alertKeyObject.keys],
			});
			return;
		}

		const { keys, objects } = this.alertKeyObject;
		if (alertStatusFilter === AlertStatusEnum.ONGOING) {
			this.alertKeyObjectSub.next({
				...this.alertKeyObject,
				snapshotKeys: keys.filter((k) => objects[k].ongoing),
			});
		} else if (alertStatusFilter === AlertStatusEnum.RECENT) {
			this.alertKeyObjectSub.next({
				...this.alertKeyObject,
				snapshotKeys: keys.filter((k) => !objects[k].ongoing),
			});
		}

	}

	resetAlertsSnapshot() {
		if (this.alertKeyObject) {
			this.alertKeyObjectSub.next({
				...this.alertKeyObject,
				snapshotKeys: [...this.alertKeyObject?.keys],
			});
		}
	}

	filterAlert(filter: AlertSearchQuery) {
		if (!this.alertKeyObject) {
			return;
		}
		let snapshotKeys = [...this.alertKeyObject.keys];
		snapshotKeys = this.filterOnImportanceLevel(filter, snapshotKeys);
		snapshotKeys = this.filterOnStatus(filter, snapshotKeys);
		snapshotKeys = this.filterOnAlertType(filter, snapshotKeys);
		snapshotKeys = this.filterOnClassification(filter, snapshotKeys);
		snapshotKeys = this.filterOnLowAndHighTrafficThreshold(filter, snapshotKeys);
		snapshotKeys = this.filterOnLowAndHighSeverityThresholdPercent(filter, snapshotKeys);
		snapshotKeys = this.filterOnBeforeOrAfterStartAndEndTime(filter, snapshotKeys);

		this.alertKeyObjectSub.next({
			...this.alertKeyObject,
			snapshotKeys,
		});
	}

	private filterOnBeforeOrAfterStartAndEndTime(filter: AlertSearchQuery, keys: string[]) {
		const filterStartBeforeOrAfter = filter.startBeforeOrAfter.value;
		const filterEndBeforeOrAfter = filter.endBeforeOrAfter.value;
		const filterStartDate = filter.startDate?.value && `${filter.startDate.value}`;
		const filterEndDate = filter.endDate?.value && `${filter.endDate.value}`;
		const filterStartTime = filter.startTime?.value && `${filter.startTime.value}`;
		const filterEndTime = filter.endTime?.value && `${filter.endTime.value}`;
		const currentSnapshotKeys = [...keys];
		const { objects } = this.alertKeyObject;
		const dateformat = 'YYYY-MM-DD HH:mm:ss';
		if (filterStartDate && filterEndDate) {
			return currentSnapshotKeys.filter((k) => {
				const alert = objects[k];
				const alertStartFormatted = alert.startTime ? moment.utc(alert.startTime).format(dateformat) : undefined;
				const alertEndFormatted = alert.endTime ? moment.utc(alert.endTime).format(dateformat) : undefined;

				const startTime = moment.utc(filterStartTime, 'HH:mm:ss').toObject();
				const endTime = moment.utc(filterEndTime, 'HH:mm:ss').toObject();
				const filterStartFormatted = moment.utc(filterStartDate).add({hours: startTime.hours, minutes: startTime.minutes, seconds: startTime.seconds}).format(dateformat);
				const filterEndFormatted = moment.utc(filterEndDate).add({hours: endTime.hours, minutes: endTime.minutes, seconds: endTime.seconds}).format(dateformat);

				if (filterStartBeforeOrAfter === BEFORE_VALUE && filterEndBeforeOrAfter === AFTER_VALUE) {
					return alertStartFormatted < filterStartFormatted && alertEndFormatted >= filterEndFormatted;
				}
				if (filterStartBeforeOrAfter === AFTER_VALUE && filterEndBeforeOrAfter === BEFORE_VALUE) {
					return alertStartFormatted >= filterStartFormatted && alertEndFormatted < filterEndFormatted;
				}
				if (filterStartBeforeOrAfter === AFTER_VALUE && filterEndBeforeOrAfter === AFTER_VALUE) {
					return alertStartFormatted > filterStartFormatted && alertEndFormatted >= filterEndFormatted;
				}
				if (filterStartBeforeOrAfter === BEFORE_VALUE && filterEndBeforeOrAfter === BEFORE_VALUE) {
					return alertStartFormatted < filterStartFormatted && alertEndFormatted < filterEndFormatted;
				}
			});
		} else if (filterStartDate) {
			return currentSnapshotKeys.filter((k) => {
				const alert = objects[k];
				const alertStartTimFormatted = alert.startTime ? moment.utc(alert.startTime).format(dateformat) : undefined;

				const startTime = moment.utc(filterStartTime, 'HH:mm:ss').toObject();
				const filterStartFormatted = moment.utc(filterStartDate).add({hours: startTime.hours, minutes: startTime.minutes, seconds: startTime.seconds}).format(dateformat);

				if (alertStartTimFormatted && filterStartBeforeOrAfter === BEFORE_VALUE) {
					return alertStartTimFormatted < filterStartFormatted;
				} else if (alertStartTimFormatted && filterStartBeforeOrAfter === AFTER_VALUE) {
					return alertStartTimFormatted >= filterStartFormatted;
				}

				return false;
			});
		} else if (filterEndDate) {
			return currentSnapshotKeys.filter((k) => {
				const alert = objects[k];
				const alertEndFormatted = alert.endTime ? moment.utc(alert.endTime).format(dateformat) : undefined;

				const endTime = moment.utc(filterEndTime, 'HH:mm:ss').toObject();
				const filterEndFormatted = moment.utc(filterEndDate).add({hours: endTime.hours, minutes: endTime.minutes, seconds: endTime.seconds}).format(dateformat);

				if (filterEndBeforeOrAfter === AFTER_VALUE) {
					return alertEndFormatted >= filterEndFormatted;
				}
				if (filterEndBeforeOrAfter === BEFORE_VALUE) {
					return alertEndFormatted < filterEndFormatted;
				}

				return false;
			});
		}

		return currentSnapshotKeys;
	}

	private filterOnLowAndHighSeverityThresholdPercent(filter: AlertSearchQuery, keys: string[]) {
		const lowSeverityPercent = filter.lowSeverityPercent.value;
		const highSeverityPercent = filter.highSeverityPercent.value;
		const currentSnapshotKeys = [...keys];
		const { objects } = this.alertKeyObject;
		if (lowSeverityPercent && highSeverityPercent) {
			return currentSnapshotKeys.filter(
				(k) => objects[k].severityPercent >= +lowSeverityPercent && objects[k].severityPercent <= +highSeverityPercent
			);
		}

		if (lowSeverityPercent) {
			return currentSnapshotKeys.filter((k) => objects[k].severityPercent >= +lowSeverityPercent);
		}
		if (highSeverityPercent) {
			return currentSnapshotKeys.filter((k) => objects[k].severityPercent <= +highSeverityPercent);
		}

		return currentSnapshotKeys;
	}

	private filterOnLowAndHighTrafficThreshold(filter: AlertSearchQuery, keys: string[]) {
		const thresholdUnit = filter.thresholdUnit.value;
		const lowThreshold = filter.lowThreshold.value;
		const highThreshold = filter.highThreshold.value;
		const currentSnapshotKeys = [...keys];
		const { objects } = this.alertKeyObject;
		if (lowThreshold && highThreshold) {
			if (thresholdUnit === BPS_VALUE) {
				return currentSnapshotKeys.filter(
					(k) => objects[k].impactBps >= +lowThreshold && objects[k].impactBps <= +highThreshold
				);
			} else if (thresholdUnit === BYTESPS_VALUE) {
				(k) => objects[k].impactBps >= +lowThreshold / 8 && objects[k].impactBps <= +highThreshold / 8
			}
			return currentSnapshotKeys.filter(
				(k) => objects[k].impactPps >= +lowThreshold && objects[k].impactPps <= +highThreshold
			);
		}

		if (lowThreshold) {
			if (thresholdUnit === BPS_VALUE) {
				return currentSnapshotKeys.filter((k) => objects[k].impactBps >= +lowThreshold);
			} else if (thresholdUnit === BYTESPS_VALUE) {
				return currentSnapshotKeys.filter((k) => objects[k].impactBps >= +lowThreshold / 8);
			}
			return currentSnapshotKeys.filter((k) => objects[k].impactPps >= +lowThreshold);
		}
		if (highThreshold) {
			if (thresholdUnit === BPS_VALUE) {
				return currentSnapshotKeys.filter((k) => objects[k].impactBps <= +highThreshold);
			} else if (thresholdUnit === BYTESPS_VALUE) {
				return currentSnapshotKeys.filter((k) => objects[k].impactBps <= +lowThreshold / 8);
			}
			return currentSnapshotKeys.filter((k) => objects[k].impactPps <= +highThreshold);
		}
		return currentSnapshotKeys;
	}

	private filterOnClassification(filter: AlertSearchQuery, keys: string[]) {
		const classification = assertValue(filter.classification.value).toString();
		const currentSnapshotKeys = [...keys];
		const { objects } = this.alertKeyObject;
		if (classification && classification !== ALL_VALUE) {
			return currentSnapshotKeys.filter((k) => objects[k].classification === classification);
		}
		return currentSnapshotKeys;
	}

	private filterOnAlertType(filter: AlertSearchQuery, keys: string[]) {
		const alertType = assertValue(filter.alertType.value).toString();
		const currentSnapshotKeys = [...keys];
		const { objects } = this.alertKeyObject;
		if (alertType !== ALL_VALUE) {
			return currentSnapshotKeys.filter((k) => objects[k].alertType.includes(alertType));
		}
		return currentSnapshotKeys;
	}

	private filterOnStatus(filter: AlertSearchQuery, keys: string[]) {
		const onGoing = filter.onGoing.value;
		const recent = filter.recent.value;
		const { objects } = this.alertKeyObject;
		let currentSnapshotKeys = [...keys];
		if (onGoing && !recent) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => objects[k].ongoing);
		}

		if (!onGoing && recent) {
			currentSnapshotKeys = currentSnapshotKeys.filter((k) => !objects[k].ongoing);
		}
		return currentSnapshotKeys;
	}

	private filterOnImportanceLevel(filter: AlertSearchQuery, keys: string[]) {
		const importanceLevels: string[] = [];
		filter.importanceHigh.value && importanceLevels.push('high');
		filter.importanceMedium.value && importanceLevels.push('medium');
		filter.importanceLow.value && importanceLevels.push('low');
		const currentSnapshotKeys = [...keys];
		if (importanceLevels.length > 0) {
			return currentSnapshotKeys.filter((k) => importanceLevels.includes(this.alertKeyObject.objects[k].importance));
		}

		return currentSnapshotKeys;
	}

	sortAlerts(sort: Sort) {
		const keyName = sort.active;
		function compare(a: Alert, b: Alert): number {
			if (typeof a[keyName] === 'number' || typeof a[keyName] === 'bigint') {
				return +a[keyName] - +b[keyName];
			}

			if (typeof a[keyName] === 'string') {
				return (<string>a[keyName]).localeCompare(<string>b[keyName]);
			}
			return 0;
		}

		const { snapshotKeys, objects } = this.alertKeyObject;
		let currentSnapshotKeys = [...snapshotKeys];
		if (sort.direction === 'asc') {
			currentSnapshotKeys = currentSnapshotKeys.sort((aKey, bKey) => {
				return compare(objects[aKey], objects[bKey]);
			});
		} else if (sort.direction === 'desc') {
			currentSnapshotKeys = currentSnapshotKeys.sort((aKey, bKey) => {
				return compare(objects[bKey], objects[aKey]);
			});
		}

		this.alertKeyObjectSub.next({
			...this.alertKeyObject,
			snapshotKeys: currentSnapshotKeys,
		});
	}

	removeAlertFromSnapshot(alertId: number) {
		const snapshotKeys = this.alertKeyObject.snapshotKeys.filter((id) => id !== alertId.toString());
		this.alertKeyObjectSub.next({
			...this.alertKeyObject,
			snapshotKeys,
		});
	}

	getAlerts(refresh = true) {
		if (refresh || !this.alertKeyObject?.keys.length) {
			const getCustomerAlertReportUrl = URLS.getApiUrl(
				URLS.CUSTOMER_ALERTS,
				assertValue(this.sessionStore.currentCompany.companyID)
			);

			// notify subscribers that it is reloading.
			this.alertKeyObjectSub.next(undefined);
			const request = this.http.get<ApiResponse<AlertCollection>>(getCustomerAlertReportUrl, {}).pipe(
				catchError((e: Error) => {
					// to complete the global gets.
					this.alertKeyObjectSub.next({
						objects: {},
						keys: [],
						snapshotKeys: [],
						defaultZoneName: undefined,
					});
					return throwError(() => e);
				}),
				tap((response) => {
					if (isSucceededResponse(response)) {
						this.defaultZoneNameSub.next(assertValue(response.dataObject).defaultZoneName);
						const alertKeyObject = getAlertKeyObject(response);
						// The use of addPropertiesToEachElement is emulating the addition of region and site properties, in the future this func is not needed
						alertKeyObject.objects = addPropertiesToEachObject<Alert>(alertKeyObject.objects, {
							region: this.sessionStore.currentCompanyDetail.region,
							site: this.sessionStore.currentCompanySite,
						});
						this.alertKeyObjectSub.next(alertKeyObject);
						this.alertsUpdateEvent.next(true);
					}
				})
			);

			if (this.intervalAlertsSub) {
				this.intervalAlertsSub.unsubscribe();
			}
			this.intervalAlertsSub = interval(updateDelay)
				.pipe(
					startWith(0),
					mergeMap(() => request)
				)
				.subscribe();
		}
	}

	getAlertsByPeriod(startTime: string, endTime: string): AlertReportsDataSource[] {
		return this.alertKeyObject.keys.filter((alertKey) => {
			const alert = this.alertKeyObject.objects[alertKey];
			return alert.startTime && moment.utc(alert.startTime as string).isBetween(moment.utc(startTime), moment.utc(endTime).add(1, 'day'))
		}).map(key => getAlertReport(this.alertKeyObject.objects[key]));
	}

	getAlertDetails(queryParams: AlertDetailQueryParam) {
		const alertId = assertValue(queryParams.alertId, MISSING_ROUTE_DATA.replace('{data}', 'alertId'));
		const startTime = assertValue(queryParams.startTime, MISSING_ROUTE_DATA.replace('{data}', 'startTime'));
		const endTime = assertValue(queryParams.endTime, MISSING_ROUTE_DATA.replace('{data}', 'endTime'));
		const unit = assertValue(queryParams.unit, MISSING_ROUTE_DATA.replace('{data}', 'unit'));
		const getCustomerAlertByIdUrl = URLS.getApiUrl(
			URLS.CUSTOMER_ALERT_BY_ID,
			assertValue(this.sessionStore.currentCompany.companyID)
		).replace('{alertId}', alertId.toString());

		this.alertDetailKeyObjectSub.next(undefined);
		this.http
			.get<ApiResponse<AlertDetailCollection>>(getCustomerAlertByIdUrl, {
				params: { startTime, endTime, unit },
			})
			.pipe(
				tap((response) => {
					const alertDetailKeyObject: AlertDetailKeyObject = {
						defaultZoneName: assertValue(response.dataObject).defaultZoneName,
						keys: [alertId],
						snapshotKeys: [alertId],
						objects: {[alertId]: response.dataObject.alertData}
					};
					this.alertDetailKeyObjectSub.next(alertDetailKeyObject);
				}),
				catchError((err: HttpErrorResponse) => {
					if (err.status === 404) {
						let dialog: any;
						try {
							dialog = this.dialogService.openConfirmDialog(DDOS_CONFIRM_DIALOG_NO_DATA);
						} catch (error) {
							console.log(error);
						}
						dialog.afterClosed().subscribe((goBack) => {
							if (goBack) {
								this.location.back();
							}
						});
					}
					return throwError(() => new Error());
				})
			)
			.subscribe()
	}

}

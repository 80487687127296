/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Component, OnInit } from '@angular/core';
import { SessionStore } from '@app/stores/session/session.store';
import { ActivatedRoute, Router } from '@angular/router';

type DisplayMsg = {
	header: string;
	body: string;
};

const enum ERROR_CODES {
	NOT_FOUND = '404',
	BAD_GATEWAY = '502',
	GATEWAY_TIMEOUT = '504',
}

export const displayMessages: { [key: string]: DisplayMsg } = {
	'404': {
		header: 'Your Business Direct ID is not found in the system',
		body: 'Please contact your Business Center Company Administrator or the Business Center Helpdesk at rm-emkc@att.com for assistance.',
	},
	'502': {
		header: 'Page under maintenance please try again later',
		body: 'Sorry for the inconvenience.',
	},
	'504': {
		header: 'Something went wrong',
		body: 'If this message is concurrent, please email threat@att.com or call 844-288-3367.',
	},
};

@Component({
	selector: 'ddos-ui-bdid-not-found',
	templateUrl: './bdid-not-found.component.html',
	styleUrls: ['./bdid-not-found.component.scss'],
})
export class BdidNotFoundComponent implements OnInit {
	errorCode: string = ERROR_CODES.NOT_FOUND;

	constructor(
		private sessionStore: SessionStore,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.sessionStore.isAuthenticatedAndReady$.subscribe((isAuth) => {
			if (isAuth) {
				this.router.navigate([`/home`]);
			}
		});
		this.assignErrorCodeValue();
	}

	private assignErrorCodeValue() {
		this.errorCode = this.route.snapshot.queryParamMap.get('code');
	}

	get errorMsg(): { header: string; body: string } {
		if (!displayMessages[this.errorCode]) {
			return displayMessages[ERROR_CODES.BAD_GATEWAY];
		}
		return displayMessages[this.errorCode];
	}
}

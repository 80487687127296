<ng-container *ngIf="!hasData()">
  <h3 class="no-data-block mat-h1" data-cy="table-no-data" [innerHtml]="noDataMessage"></h3>
</ng-container>

<div class="table-grid">
  <div class="table-container">
    <table
      mat-table
      matSort
      [matSortActive]="defaultSortingColumn"
      [matSortDirection]="defaultSortingDirection"
      class="ddos-table"
      [hidden]="!hasData()"
      [dataSource]="tblDataSource"
      (matSortChange)="sortTable($event)"
    >
      <caption></caption>
      <ng-container *ngIf="isSelectable">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
      </ng-container>

      <!-- action column -->
      <ng-container *ngIf="rowActionMenu?.length; else noMenu">
        <ng-container matColumnDef="actionMenu">
          <th mat-header-cell *matHeaderCellDef>&zwnj;</th>
          <td [class.text-right]="true" mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" tabindex="0">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="onClick(element, action)" mat-menu-item *ngFor="let action of rowActionMenu" tabindex="0">
                {{ action }}
              </button>
            </mat-menu>
          </td>
        </ng-container>
      </ng-container>

      <ng-template #noMenu>
        <ng-container *ngIf="rowActionSingleIcon; else iconsArray">
          <ng-container *ngIf="rowActionSingleIcon?.length" [matColumnDef]="rowActionSingleIcon">
            <th mat-header-cell *matHeaderCellDef>&zwnj;</th>
            <td mat-cell *matCellDef="let element" [id]="rowActionIcon" (click)="emitRowAction(element)">
              <button mat-button tabindex="0">
                <mat-icon>{{ rowActionSingleIcon }}</mat-icon>
              </button>
            </td>
          </ng-container>
        </ng-container>

        <ng-template #iconsArray>
          <ng-container *ngIf="rowActionIconList?.length">
            <ng-container *ngFor="let action of rowActionIconList" [matColumnDef]="action.icon">
              <th mat-header-cell *matHeaderCellDef>&zwnj;</th>
              <td mat-cell *matCellDef="let element" [id]="action.icon">
                <button mat-button (click)="emitRowAction(element, action.icon)" tabindex="0">
                  <mat-icon
                    [id]="action.icon"
                    role="button"
                    [matTooltip]="action.tooltip"
                  >
                    {{ action.icon }}
                  </mat-icon>
                </button>
              </td>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-template>

      <ng-container *ngFor="let tblColumn of tblColumns" [matColumnDef]="tblColumn.columnName">
        <!-- if sortable column header -->
        <ng-container *ngIf="tblColumn.isSortable; else notSortable">
          <th
            mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="tblColumn.dataKey"
            [arrowPosition]="tblColumn.sortArrowPosition === 'right' ? 'before' : 'after'"
          >
            {{ tblColumn.columnName }}
            <mat-icon
              *ngIf="tblColumn.columnNameIcon"
              [color]="tblColumn.columnNameIconColor"
              [matTooltip]="tblColumn.tooltip"
            >
              {{ tblColumn.columnNameIcon }}
            </mat-icon>
          </th>
        </ng-container>
        <!-- else not sortable -->
        <ng-template #notSortable>
          <th mat-header-cell *matHeaderCellDef [class.text-right]="tblColumn.sortArrowPosition == 'right'">
            {{ tblColumn.columnName }}
            <mat-icon *ngIf="tblColumn.columnNameIcon" [color]="tblColumn.dataKeyIconColor" [matTooltip]="tblColumn.tooltip">
              {{ tblColumn.columnNameIcon }}
            </mat-icon>
          </th>
        </ng-template>

        <!-- column data for innerHtml-->
        <ng-container *ngIf="tblColumn.isInnerHtmlString; else og">
          <td
            (keyup.enter)="onClick(element, tblColumn.dataKeyIcon)"
            (click)="onClick(element, tblColumn.dataKeyIcon)"
            mat-cell
            *matCellDef="let element"
            [class.text-right]="tblColumn.sortArrowPosition == 'right'"
            [innerHtml]="!tblColumn.isDataKeyHidden && element[tblColumn.dataKey] | safeHtml"
          >
            <mat-icon *ngIf="tblColumn.dataKeyIcon" [class]="tblColumn.dataKeyIconColor">
              {{ tblColumn.dataKeyIcon }}
            </mat-icon>
          </td>
        </ng-container>

        <!-- default column data-->
        <ng-template #og>
          <td
            (keyup.enter)="onClick(element, tblColumn.dataKeyIcon)"
            (click)="onClick(element, tblColumn.dataKeyIcon)" mat-cell *matCellDef="let element"
            [class.text-right]="tblColumn.sortArrowPosition == 'right'"
            [ngClass]="{'highlight-row': element.highlightRow, 'highlight-field': element.highlightRow && tblColumn.dataKey === element.highlightRow}"
            [tabindex]="hasRowLink ? 0 : -1">
            <span *ngIf="tblColumn.isIconData; else dataColumn" [class.center-aligned]="tblColumn.isRowCenterAligned">
              <mat-icon [class]="getIconColor(element, tblColumn.dataKeyIconColor)">
                {{ element | propertyGetter : tblColumn.dataKey }}
              </mat-icon>
            </span>
            <ng-template #dataColumn>
              <ng-container *ngIf="!tblColumn.isLink; else linkColumn">
                <span [class.center-aligned]="tblColumn.isRowCenterAligned">
                  {{ element | propertyGetter : tblColumn.dataKey }}
                </span>
              </ng-container>
              <ng-template #linkColumn>
                <span [class.center-aligned]="tblColumn.isRowCenterAligned">
                  <a routerLink="{{[element.link]}}" [queryParams]="element.queryParams" [tabindex]="0" class="ddos-primary-500">{{ element | propertyGetter : tblColumn.dataKey }}</a>
                </span>
              </ng-template>
            </ng-template>
          </td>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="isStickyHeader; else default">
        <tr mat-header-row *matHeaderRowDef="tblHeaders; sticky: true"></tr>
      </ng-container>

      <ng-template #default>
        <tr mat-header-row *matHeaderRowDef="tblHeaders"></tr>
      </ng-template>
      <tr mat-row *matRowDef="let row; columns: tblHeaders"></tr>
    </table>
  </div>

  <div [hidden]="!isPageable || !hasData()" data-cy="paginator">
    <!-- Pagination -->
    <mat-paginator
      [class.mat-paginator-sticky]="isStickyHeader"
			[length]="tblDataSource.data.length"
      [pageSizeOptions]="paginationSizes"
      [pageSize]="defaultPageSize"
      showFirstLastButtons
      (page)="onPaginatorChange($event)"
    >
    </mat-paginator>
  </div>
</div>

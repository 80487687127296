/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class InnerHtmlPipe {
	constructor(protected sanitizer: DomSanitizer) {}

	transform(htmlString: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(htmlString);
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppLayoutRouting } from '@src/app/routing-constants';

const ACCESS_DENIED_ERROR = 'Access Denied';
const PAGE_NOT_FOUND_ERROR = 'Page Not Found';

@Component({
	selector: 'ddos-ui-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit {
	errorMessage: string;

	constructor(private router: Router) {}

	ngOnInit(): void {
		this.errorMessage =
			this.router.url === `${AppLayoutRouting.USER_ERROR}` ? ACCESS_DENIED_ERROR : PAGE_NOT_FOUND_ERROR;
	}

	async navigateToPortal() {
		await this.router.navigate(['/home']);
	}
}

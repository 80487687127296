/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { toKeyObject } from '@src/app/utils/key-object';
import { ApiResponse } from '../common.model';
import {
	ADMIN_CONTACT_ACTION_ICON,
	AdminDataSource,
	LiaisonContact,
	LiaisonContactKeyObject,
	LiaisonContacts,
} from './admin.model';

const getAdminDataSource = (liaison: LiaisonContact): AdminDataSource => {
	return {
		name: `${liaison.firstName} ${liaison.lastName}`,
		liaisonID: liaison.liaisonID,
		company: liaison.companyName,
		email: liaison.email,
		cellPhone: liaison.cellPhone,
		businessPhone: liaison.businessPhone,
		sendNotifications: liaison.sendNotifications ? 'Yes' : 'No',
		disableActionIcon: ADMIN_CONTACT_ACTION_ICON.DELETE,
	};
};

export const getAdminDataSources = (liaisonKeyObject: LiaisonContactKeyObject): AdminDataSource[] => {
	return liaisonKeyObject?.keys.map((k) => getAdminDataSource(liaisonKeyObject.objects[k]));
};

export const getLiaisonContractKeyObject = (response: ApiResponse<LiaisonContacts>): LiaisonContactKeyObject => {
	return {
		...toKeyObject(response.dataObject.liaisonList, 'liaisonID'),
	};
};

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LoaderStore } from '@src/app/stores/loader/loader.store';
import { SessionStore } from '@src/app/stores/session/session.store';
import { Observable } from 'rxjs';

@Component({
	selector: 'ddos-ui-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
	hasRequestsInProgress$: Observable<boolean>;

	isAuthenticatedAndReady$: Observable<boolean>;

	@Input() inComponent: boolean = false;

	@Input() forceDisplay: boolean = false;

	constructor(
		private loaderStore: LoaderStore,
		private sessionStore: SessionStore,
	) {}

	ngOnInit(): void {
		this.hasRequestsInProgress$ = this.loaderStore.httpProgress$;
		this.isAuthenticatedAndReady$ = this.sessionStore.isAuthenticatedAndReady$;
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { MatSelectChange } from '@angular/material/select';
import { Params } from '@angular/router';

export type NetworkAddress = string;

export type PrimitiveValue = bigint | number | string | string[] | boolean | null | undefined | Params;

export type CompanyIdentifier = {
	companyID: number;
	companyName: string;
};

export type TimeRangeParam = {
	startTime: string;
	endTime?: string;
};

export type DateRangeParam = {
	startDate: string;
	endDate: string;
};

export type MonthObject = {
	month: string;
	value: number;
};

export type NameValueOption = {
	name: string;
	value: PrimitiveValue;
};

export type TableDataSource = { [k: string]: PrimitiveValue };

export type TableFilter = NameValueOption & {
	options: NameValueOption[];
	tooltip?: string;
};

export type TableFilterDropDownOption = {
	changeOption: MatSelectChange;
	selectedFilter: TableFilter;
};

export type TableColumn = {
	columnName: string;
	dataKey: string;
	isIconData?: boolean;
	tooltip?: string;
	// key to get tooltip from data source.
	tooltipKey?: string;
	columnNameIcon?: string | string[];
	columnNameIconColor?: string;
	dataKeyIcon?: string | string[];
	dataKeyIconColor?: string;
	sortArrowPosition?: 'right' | 'left';
	isInnerHtmlString?: boolean;
	isSortable?: boolean;
	isTextFilter?: boolean;
	isFilterable?: boolean;
	isDataKeyHidden?: boolean;
	isRowCenterAligned?: boolean;
	isLink?: boolean;
};

export type TableRowClick = {
	actionIcon: string | string[];
	rowValue: TableDataSource;
};

export type ApiResponse<T> = {
	serviceName: string;
	returnCode: number;
	returnDescription: string;
	dataObject?: T;
};

export enum CompanyOfferType {
	NON_REACTIVE = 0,
	REACTIVE = 1,
	BANDWIDTH_TIER = 2,
}

export enum ServiceType {
	RL = 'RL',
	IDC = 'IDC',
}

export const updateDelay: number = 3 * 60 * 1000;

export type NodeValue = string | number | boolean | Date | undefined;

export type QueryNode = {
	value: NodeValue;
	options?: Set<NodeValue>;
};

export enum ReturnCode {
	SUCCESS = 0,
	ERROR = 1,
	NOT_FOUND = 2,
}

export type UnitType = 'bps' | 'pps';

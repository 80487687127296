/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';
import { formatMitigationDuration } from '@src/app/stores/report/mitigation/mitigation.converter';

@Pipe({
	name: 'formatDuration',
})
export class DurationPipe implements PipeTransform {
	transform(durationString: string): string {
		return formatMitigationDuration(durationString);
	}
}

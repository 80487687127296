/* Copyright 2024, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NameValueOption, PrimitiveValue } from '@src/app/stores/common.model';
import { DateFilterFn } from '@angular/material/datepicker';
import moment from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SearchStatusControls } from '../../../services/search/search.model';

@Component({
	selector: 'ddos-ui-search-date-filter',
	templateUrl: './search-date-filter.component.html',
	styleUrls: ['./search-date-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDateFilterComponent {
	@Input() statusFormGroup: FormGroup<SearchStatusControls>;

	@Input() statusStart: NameValueOption[];

	@Input() statusEnd: NameValueOption[];

	@Input() minStartDate: string;

	@Input() availableStatuses: NameValueOption[] = [];

	@Input() tooltipOngoingMsg: string;

	@Input() tooltipRecentMsg: string;

	startTimeFilter: DateFilterFn<Date | null> = (d: Date) => {
		const statusFormGroupValue = this.statusFormGroup.value;
		const isBeforeNow: boolean = moment.utc(d).isSameOrBefore(moment.utc());
		const isAfterMinStartDate: boolean =
			!!this.minStartDate && moment.utc(d).isSameOrAfter(moment.utc(this.minStartDate));

		if (statusFormGroupValue.endDate) {
			const isBeforeEnd: boolean =
				moment.utc(d).isSameOrBefore(moment.utc(statusFormGroupValue.endDate)) &&
				moment.utc(d).isSameOrBefore(moment.utc());
			return isBeforeEnd && isAfterMinStartDate;
		}
		return isBeforeNow && isAfterMinStartDate;
	};

	endTimeFilter: DateFilterFn<Date | null> = (d: Date) => {
		const statusFormGroupValue = this.statusFormGroup.value;

		if (statusFormGroupValue.startDate) {
			if (statusFormGroupValue.end === 'before') {
				return moment.utc(d).isBetween(moment.utc(statusFormGroupValue.startDate), moment.utc().add(1, 'day'));
			}
			return moment.utc(d).isBetween(moment.utc(statusFormGroupValue.startDate), moment.utc());
		}
		if (statusFormGroupValue.end === 'before') {
			return moment.utc(d).isBetween(this.minStartDate, moment.utc().add(1, 'day'));
		}
		return moment.utc(d).isBetween(this.minStartDate, moment.utc());
	};

	onStatusChange(event: MatCheckboxChange, status: NameValueOption) {
		this.statusFormGroup.get(status.name).patchValue(event.checked);
		if (status.name === 'ongoing' && event.checked === true) {
			this.resetEndDateOnOngoing();
		}

		this.checkEnableRecent();
	}

	private checkEnableRecent() {
		let enableRecent = false;
		const isOngoing = this.statusFormGroup.value.ongoing;
		const isRecent = this.statusFormGroup.value.recent;

		enableRecent = !isOngoing && isRecent;

		if (enableRecent) {
			this.statusFormGroup.get('end').enable({ onlySelf: true });
			this.statusFormGroup.get('isEnd').enable({ onlySelf: true });
			this.statusFormGroup.get('endTime').enable({ onlySelf: true });
			this.statusFormGroup.get('endDate').enable({ onlySelf: true });
			this.statusFormGroup.get('isEnd').patchValue(true);
		} else {
			this.statusFormGroup.get('isEnd').patchValue(false);
			this.statusFormGroup.get('end').disable({ onlySelf: false });
			this.statusFormGroup.get('isEnd').disable({ onlySelf: false });
			this.statusFormGroup.get('endTime').disable({ onlySelf: false });
			this.statusFormGroup.get('endDate').disable({ onlySelf: false });
		}
	}

	private resetEndDateOnOngoing() {
		this.statusFormGroup.get('endDate').patchValue(moment.utc().add(1, 'day').format('YYYY-MM-DD'));
		this.statusFormGroup.get('end').patchValue('before');
		this.statusFormGroup.get('isEnd').patchValue(true);
	}

	protected convertToBooleanInput(value: PrimitiveValue): boolean {
		return !!value;
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { SearchWizardAlertService } from '@src/app/components/shared/services/search/search-wizard-alert.service';
import { NameValueOption } from '@src/app/stores/common.model';
import { BooleanInput } from '@angular/cdk/coercion';
import { WizardType, SearchWizardDialogData } from '../../services/dialog/dialog-factory.service';
import { AlertLowHighControls, AlertSearchForm, AlertSearchFormValuesPartial } from '../../services/search/search.model';
import { Subject, debounceTime, distinctUntilChanged, pairwise, startWith, takeUntil } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
	selector: 'ddos-ui-search-wizard-alert',
	templateUrl: './search-wizard-alert.component.html',
	styleUrls: ['./search-wizard-alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchWizardAlertComponent implements OnInit, OnDestroy {
	BooleanInput: BooleanInput;

	wizardType: WizardType;

	alertSeverity: NameValueOption[];

	alertClass: NameValueOption[];

	alertType: NameValueOption[];

	alertClassification: NameValueOption[];

	availableStatuses: NameValueOption[];

	statusStart: NameValueOption[];

	statusEnd: NameValueOption[];

	alertMaxTrafficImpact: NameValueOption[];

	alertMaxSeverityLowBoundPercent: NameValueOption[];

	alertMaxSeverityHighBoundPercent: NameValueOption[];

	itemsPerPage: number;

	alertSearchWizardForm: FormGroup<AlertSearchForm>;

	searchButtonDisabled: boolean = false;

	private destroy$ = new Subject<boolean>();

	mitigationSearchForm: FormGroup<any>;

	mitigationIpVersion: NameValueOption[];

	mitigationType: NameValueOption[];

	hideSearchString: boolean = false;

	minAlertStartDate: string;

	readonly tooltipRecentMsg: string = 'All closed alerts dating back to the last 6 months.';

	readonly tooltipOngoingMsg: string = 'Selecting "Ongoing" checkbox will disable the "End by" date selection option for "Ongoing" and "Recent" (if selected) alerts.';

	constructor(
		public dialogRef: MatDialogRef<SearchWizardAlertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: SearchWizardDialogData,
		public wizardService: SearchWizardAlertService,
	) { }

	ngOnInit(): void {
		this.wizardType = this.data.templateType;
		this.alertSearchWizardForm = this.data.context;
		this.configureAlertWizardElements();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	configureAlertWizardElements() {
		if (!this.alertSearchWizardForm) {
			return;
		}
		this.initAvailableValues();

		this.alertSearchWizardForm.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			startWith({}),
			pairwise(),
			takeUntil(this.destroy$)
		).subscribe(([prevForm, nextForm]) => {
			if (!isEqual(prevForm, nextForm)) {
				this.checkFormChanges(nextForm);
			}
		});
	}

	private initAvailableValues() {
		this.alertSeverity = this.wizardService.getAlertSeverityLevels();
		this.alertType = this.wizardService.getAlertTypesDropdowns();
		this.alertClassification = this.wizardService.getAlertClassificationDropdowns();
		this.availableStatuses = this.wizardService.getStatus();
		this.statusStart = this.wizardService.getStatusByType('START');
		this.statusEnd = this.wizardService.getStatusByType('END');
		this.alertMaxTrafficImpact = this.wizardService.getAlertTrafficUnitsDropDowns();
		this.alertMaxSeverityLowBoundPercent = this.wizardService.getAlertSeverityPercentDropdownsByType('LOW');
		this.alertMaxSeverityHighBoundPercent = this.wizardService.getAlertSeverityPercentDropdownsByType('HIGH');
		this.minAlertStartDate = moment.utc().subtract(6, 'months').toLocaleString();
		this.hideSearchString = !!this.alertSearchWizardForm.value.search.searchString
	}

	private checkFormChanges(form: AlertSearchFormValuesPartial) {
		if (!!form.maxSeverityPercent.highValue || !!form.maxSeverityPercent.lowValue) {
			this.wizardService.updateAlertValidators(this.alertSearchWizardForm.controls['maxSeverityPercent']);
		}

		if (!!form.maxImpactAlertTraffic.highValue || !!form.maxImpactAlertTraffic.lowValue) {
			this.wizardService.updateAlertValidators(this.alertSearchWizardForm.controls['maxImpactAlertTraffic'] as unknown as FormGroup<AlertLowHighControls>);
		}

		if (!!form.alertStatus.startDate || !!form.alertStatus.endDate) {
			this.alertSearchWizardForm.get('alertStatus').get('endDate').updateValueAndValidity();
			this.alertSearchWizardForm.get('alertStatus').get('startDate').updateValueAndValidity();
			this.alertSearchWizardForm.get('alertStatus').get('endTime').updateValueAndValidity();
			this.alertSearchWizardForm.get('alertStatus').get('startTime').updateValueAndValidity();
		}

		this.searchButtonDisabled = !this.alertSearchWizardForm.valid;
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { KeyObjects, OrUndefineable } from '@src/types/types.utils';
import { CompanyIdentifier } from '../common.model';

export const US_STATES = [
	{ type: 'ALABAMA', value: 'AL' },
	{ type: 'ALASKA', value: 'AK' },
	{ type: 'AMERICAN SAMOA', value: 'AS' },
	{ type: 'ARIZONA', value: 'AZ' },
	{ type: 'ARKANSAS', value: 'AR' },
	{ type: 'CALIFORNIA', value: 'CA' },
	{ type: 'COLORADO', value: 'CO' },
	{ type: 'CONNECTICUT', value: 'CT' },
	{ type: 'DELAWARE', value: 'DE' },
	{ type: 'DISTRICT OF COLUMBIA', value: 'DC' },
	{ type: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
	{ type: 'FLORIDA', value: 'FL' },
	{ type: 'GEORGIA', value: 'GA' },
	{ type: 'GUAM', value: 'GU' },
	{ type: 'HAWAII', value: 'HI' },
	{ type: 'IDAHO', value: 'ID' },
	{ type: 'ILLINOIS', value: 'IL' },
	{ type: 'INDIANA', value: 'IN' },
	{ type: 'IOWA', value: 'IA' },
	{ type: 'KANSAS', value: 'KS' },
	{ type: 'KENTUCKY', value: 'KY' },
	{ type: 'LOUISIANA', value: 'LA' },
	{ type: 'MAINE', value: 'ME' },
	{ type: 'MARSHALL ISLANDS', value: 'MH' },
	{ type: 'MARYLAND', value: 'MD' },
	{ type: 'MASSACHUSETTS', value: 'MA' },
	{ type: 'MICHIGAN', value: 'MI' },
	{ type: 'MINNESOTA', value: 'MN' },
	{ type: 'MISSISSIPPI', value: 'MS' },
	{ type: 'MISSOURI', value: 'MO' },
	{ type: 'MONTANA', value: 'MT' },
	{ type: 'NEBRASKA', value: 'NE' },
	{ type: 'NEVADA', value: 'NV' },
	{ type: 'NEW HAMPSHIRE', value: 'NH' },
	{ type: 'NEW JERSEY', value: 'NJ' },
	{ type: 'NEW MEXICO', value: 'NM' },
	{ type: 'NEW YORK', value: 'NY' },
	{ type: 'NORTH CAROLINA', value: 'NC' },
	{ type: 'NORTH DAKOTA', value: 'ND' },
	{ type: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
	{ type: 'OHIO', value: 'OH' },
	{ type: 'OKLAHOMA', value: 'OK' },
	{ type: 'OREGON', value: 'OR' },
	{ type: 'PALAU', value: 'PW' },
	{ type: 'PENNSYLVANIA', value: 'PA' },
	{ type: 'PUERTO RICO', value: 'PR' },
	{ type: 'RHODE ISLAND', value: 'RI' },
	{ type: 'SOUTH CAROLINA', value: 'SC' },
	{ type: 'SOUTH DAKOTA', value: 'SD' },
	{ type: 'TENNESSEE', value: 'TN' },
	{ type: 'TEXAS', value: 'TX' },
	{ type: 'UTAH', value: 'UT' },
	{ type: 'VERMONT', value: 'VT' },
	{ type: 'VIRGIN ISLANDS', value: 'VI' },
	{ type: 'VIRGINIA', value: 'VA' },
	{ type: 'WASHINGTON', value: 'WA' },
	{ type: 'WEST VIRGINIA', value: 'WV' },
	{ type: 'WISCONSIN', value: 'WI' },
	{ type: 'WYOMING', value: 'WY' },
];

export enum ADMIN_CONTACT_ACTION_ICON {
	EDIT = 'edit',
	DELETE = 'delete_outline',
}
export const ADMIN_CONTACT_ACTION_ICONS = [
	{ icon: ADMIN_CONTACT_ACTION_ICON.EDIT, tooltip: 'Edit contact' },
	{ icon: ADMIN_CONTACT_ACTION_ICON.DELETE, tooltip: 'Delete contact' },
];

export type LiaisonContactIdentifier = {
	liaisonID: number;
};

export type LiaisonContact = CompanyIdentifier &
	LiaisonContactIdentifier & {
		defaultZoneName: string;
		offerType: number;
		firstName: string;
		lastName: string;
		liaisontype: number;
		address: string;
		room: string;
		city: string;
		state: string;
		zipCode: string;
		country: string;
		businessPhoneCountry: string;
		businessPhoneArea: string;
		businessPhone: string;
		businessPhoneExt: string;
		cellPhoneCountry: string;
		cellPhoneArea: string;
		cellPhone: string;
		email: string;
		loginId: string;
		sendNotifications: boolean;
		duties: string;
		hoursOfContact: string;
		reseller: number;
		resellerCompanyName: string;
		liaisonID?: number;
	};

export type LiaisonContacts = {
	liaisonList: LiaisonContact[];
};

export type CreateOrUpdateLiaisonContact = {
	email: string;
	loginId: string;
	companyID: number;
	defaultZoneName: string;
	firstName: string;
	lastName: string;
	businessPhoneCountry: string;
	businessPhone: string;
	businessPhoneExt: string;
	cellPhone: string;
	sendNotifications: boolean;
	hoursOfContact: string;
};

export type AdminDataSource = LiaisonContactIdentifier & {
	name: string;
	company: string;
	email: string;
	cellPhone: string;
	businessPhone: string;
	sendNotifications: string;
	disableActionIcon: OrUndefineable<string>;
};

export type ContactInfo = {
	firstName: string;
	lastName: string;
	email: string;
	businessPhoneCountry: string;
	businessPhoneExt: string;
	businessPhone: string;
	cellPhone: string;
};

export type AdminDataFormSource = {
	firstName: string;
	lastName: string;
	liasonType: string;
	businessPhoneExt: string;
	businessPhone: string;
	businessPhoneCountry: string;
	cellPhone: string;
	loginId: string;
	email: string;
	sendNotifications: boolean;
	hoursOfContact: string;
	region: string;
	site: string;
};

export type LiaisonContactDetailsFormValues = {
	firstName: string;
	lastName: string;
	businessPhoneExt: string;
	businessPhone: string;
	businessPhoneCountry: string;
	cellPhone: string;
	loginId: string;
	email: string;
	sendNotifications: boolean;
	hoursOfContact?: string;
	liaisonID?: string;
};

export type LiaisonContactActionResponse = {
	companyID: number;
	requestID: number;
	requestServiceName: string;
	responseStatus: string;
	responseDetail: string;
};

export type LiaisonContactKeyObject = KeyObjects<LiaisonContact>;

export type RequestDetails = {
	companyID: number;
	requestID: number;
	requestServiceName: string;
	responseStatus: 'Pending' | 'Complete' | 'Failed';
	responseDetail: string;
};

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
	TA_DROPDOWN_ALERT_SEVERITY,
	TA_DROPDOWN_ALERT_TYPE,
	TA_WIZARD_ITEMS_PER_PAGE,
	TA_DROPDOWN_STATUS_START,
	TA_DROPDOWN_STATUS_END,
	TA_DROPDOWN_UNITS,
	TA_DROPDOWN_ALERT_LOW_SEVERITY_PERCENT,
	TA_DROPDOWN_ALERT_HIGH_SEVERITY_PERCENT,
} from '@src/app/stores/report/traffic/traffic.model';
import { NameValueOption } from '@src/app/stores/common.model';
import { AlertSearchForm, SearchWizardStatusType, AlertSeverityPercentType, AlertLowHighControls, AlertSearchFormValuesPartial } from './search.model';
import { AlertStatusFilter, AlertSearchQuery, AlertStatusEnum, } from '@src/app/stores/alert/alert.model';

@Injectable({
	providedIn: 'root',
})
export class SearchWizardAlertService {

	alertSeverity: NameValueOption[];

	readonly alertType: NameValueOption[] = [
		{ name: 'All', value: 'all'} ,
		{ name: 'Profiled', value: 'profiled'},
		{ name: 'Hosted', value: 'host'}
	];

	readonly defaultAlertClassification: NameValueOption = { name: 'All', value: 'all'};

	alertClassification: NameValueOption[];

	alertStatus: NameValueOption[];

	alertStatusStart: NameValueOption[];

	alertStatusEnd: NameValueOption[];

	alertMaxTrafficImpact: NameValueOption[];

	alertMaxSeverityLowBoundPercent: NameValueOption[];

	alertMaxSeverityHighBoundPercent: NameValueOption[];

	alertItemsPerPage: NameValueOption[];

	getAlertSeverityLevels(): NameValueOption[] {
		return [...TA_DROPDOWN_ALERT_SEVERITY];
	}

	getAlertTypesDropdowns(): NameValueOption[] {
		return this.alertType;
	}

	getAlertTypeDropdowns(): NameValueOption[] {
		return [...TA_DROPDOWN_ALERT_TYPE];
	}

	getAlertClassificationDropdowns(): NameValueOption[] {
		return this.alertClassification;
	}

	getItemsPerPage(): number {
		return TA_WIZARD_ITEMS_PER_PAGE;
	}

	getStatus(): NameValueOption[] {
		return [
			{ name: 'ongoing', value: false },
			{ name: 'recent', value: false },
		];
	}

	getStatusByType(type: SearchWizardStatusType): NameValueOption[] {
		return type === 'START' ? [...TA_DROPDOWN_STATUS_START] : [...TA_DROPDOWN_STATUS_END];
	}

	getAlertTrafficUnitsDropDowns(): NameValueOption[] {
		return [...TA_DROPDOWN_UNITS];
	}

	getAlertSeverityPercentDropdownsByType(type: AlertSeverityPercentType): NameValueOption[] {
		return type === 'LOW' ? [...TA_DROPDOWN_ALERT_LOW_SEVERITY_PERCENT] : [...TA_DROPDOWN_ALERT_HIGH_SEVERITY_PERCENT];
	}

	setAlertStatusFormValues(selectedStatus: AlertStatusFilter, form: FormGroup<AlertSearchForm>) {
		switch (selectedStatus) {
			case AlertStatusEnum.ALL:
				form.get('alertStatus').get('ongoing').patchValue(true);
				form.get('alertStatus').get('recent').patchValue(true);
				this.disableEndStatus(form);
				break;
			case AlertStatusEnum.ONGOING:
				form.get('alertStatus').get('ongoing').patchValue(true);
				form.get('alertStatus').get('recent').patchValue(false);
				this.disableEndStatus(form);
				break;
			case AlertStatusEnum.RECENT:
				form.get('alertStatus').get('recent').patchValue(true);
				form.get('alertStatus').get('ongoing').patchValue(false);
				this.enableEndStatus(form);
				break;
		}
	}

	private enableEndStatus(form: FormGroup<AlertSearchForm>) {
		form.get('alertStatus').get('isEnd').enable();
		form.get('alertStatus').get('isEnd').patchValue(true);
		form.get('alertStatus').get('end').enable();
		form.get('alertStatus').get('endTime').enable();
	}

	private disableEndStatus(form: FormGroup<AlertSearchForm>) {
		form.get('alertStatus').get('isEnd').patchValue(false);
		form.get('alertStatus').get('isEnd').disable();
		form.get('alertStatus').get('end').disable();
		form.get('alertStatus').get('endTime').disable();
	}

	updateAlertValidators(controlGroup: FormGroup<AlertLowHighControls>) {
		let newValidators: ValidatorFn[] = [];
		if (controlGroup.controls['lowValue'].value && controlGroup.controls['highValue'].value) {
			newValidators = [Validators.min(+controlGroup.controls['lowValue'].value)];
		}
		controlGroup.get('highValue').setValidators(newValidators);
		controlGroup.get('highValue').updateValueAndValidity()

		newValidators = [];
		if (controlGroup.controls['highValue'].value && controlGroup.controls['lowValue'].value) {
			newValidators = [Validators.max(+controlGroup.controls['highValue'].value)];
		}
		controlGroup.get('lowValue').setValidators(newValidators);
		controlGroup.get('lowValue').updateValueAndValidity()
	}

	setAlertClassificationOptions(alertClassificationOptions: NameValueOption[]) {
		this.alertClassification =  [this.defaultAlertClassification, ...alertClassificationOptions];
	}

	generateSearchWizardForm(): FormGroup<AlertSearchForm> {
		return new FormGroup({
			search: new FormGroup({
				searchString: new FormControl<string>({
					value: '',
					disabled: false,
				}),
				alertItemsPerPage: new FormControl(),
			}),
			severity: new FormGroup({
				high: new FormControl({
					value: true,
					disabled: false,
				}),
				medium: new FormControl({
					value: false,
					disabled: false,
				}),
				low: new FormControl({
					value: false,
					disabled: false,
				}),
				alertType: new FormControl(''),
				alertClassification: new FormControl(''),
			}),
			alertStatus: new FormGroup({
				ongoing: new FormControl<boolean>(false),
				recent: new FormControl<boolean>(false),
				isStart: new FormControl({ value: false, disabled: false }),
				isEnd: new FormControl({ value: false, disabled: true }),
				start: new FormControl(''),
				end: new FormControl({ value: '', disabled: true }),
				startDate: new FormControl(''),
				startTime: new FormControl(''),
				endDate: new FormControl({ value: '', disabled: true }),
				endTime: new FormControl({ value: '', disabled: true }),
			}),
			maxImpactAlertTraffic: new FormGroup({
				lowValue: new FormControl(null),
				highValue: new FormControl(null),
				lowHighValueUnits: new FormControl(''),
			}),
			maxSeverityPercent: new FormGroup({
				lowValue: new FormControl(null),
				highValue: new FormControl(null),
			}),
		});
	}

	getSearchQuery(search: AlertSearchFormValuesPartial): Partial<AlertSearchQuery> {
		return {
			searchString: { value: search.search?.searchString },
			size: { value: search.search?.alertItemsPerPage },
			importanceHigh: { value: search.severity?.high },
			importanceMedium: { value: search.severity?.medium },
			importanceLow: { value: search.severity?.low },
			alertType: { value: search.severity?.alertType.toLowerCase() },
			classification: { value: search.severity?.alertClassification },
			onGoing: { value: search.alertStatus?.ongoing },
			recent: { value: search.alertStatus?.recent },
			startBeforeOrAfter: { value: search.alertStatus?.isStart && search.alertStatus?.start },
			endBeforeOrAfter: { value: search.alertStatus?.isEnd && search.alertStatus?.end },
			startDate: { value: search.alertStatus?.isStart && search.alertStatus?.startDate },
			startTime: { value: search.alertStatus?.isStart && search.alertStatus?.startTime },
			endDate: { value: search.alertStatus?.isEnd && search.alertStatus?.endDate },
			endTime: { value: search.alertStatus?.isEnd && search.alertStatus?.endTime },
			lowThreshold: { value: search.maxImpactAlertTraffic?.lowValue },
			highThreshold: { value: search.maxImpactAlertTraffic?.highValue },
			thresholdUnit: { value: search.maxImpactAlertTraffic?.lowHighValueUnits },
			lowSeverityPercent: { value: search.maxSeverityPercent?.lowValue },
			highSeverityPercent: { value: search.maxSeverityPercent?.highValue },
		};
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { FormControl } from '@angular/forms';
import { SelectedCustomerOption } from '../../alert/alert.model';
import { NameValueOption, TableColumn, UnitType } from '../../common.model';

export type FacetTypes = 'Application' | 'TCP_Port' | 'UDP_Port' | 'Top_Sources' | 'Top_Destinations';

export const TA_DROPDOWN_PERIOD: NameValueOption[] = [
	{ name: 'Today', value: 'Today' },
	{ name: 'Yesterday', value: 'YESTERDAY' },
	{ name: 'This Week', value: 'THIS_WEEK' },
	{ name: 'This Month', value: 'THIS_MONTH' },
	{ name: 'This Year', value: 'THIS_YEAR' },
	{ name: 'Custom', value: 'Custom' },
];

export const TA_DROPDOWN_GRAPH_TYPE: NameValueOption[] = [
	{ name: 'Stacked', value: 'Stacked' },
	{ name: 'Bar', value: 'Bar' },
	{ name: 'Pie', value: 'Pie' },
];

export type TrafficUnitValue = UnitType | 'Bps';
export enum TA_PERIOD_ENUMS {
	TODAY = 'Today',
	YESTERDAY = 'YESTERDAY',
	THIS_WEEK = 'THIS_WEEK',
	THIS_MONTH = 'THIS_MONTH',
	THIS_YEAR = 'THIS_YEAR',
	CUSTOM = 'Custom',
}

export const TA_DROPDOWN_UNITS: NameValueOption[] = [
	{ name: 'bps', value: 'bps' },
	{ name: 'Bps', value: 'Bps' },
	{ name: 'pps', value: 'pps' },
	// { name: 'Bytes', value: 'Bytes' },
	// { name: 'Packets', value: 'Packets' },
];

export const TA_DROPDOWN_VOLUME: NameValueOption[] = [
	{ name: 'Average', value: 'Average' },
	{ name: 'Current', value: 'Current' },
];

export const TA_DROPDOWN_SEARCH_LIMIT: NameValueOption[] = [{ name: 'limit', value: 100 }];

export const TA_DROPDOWN_ITEMS_PER_PAGE: NameValueOption[] = [{ name: 'perPage', value: 10 }];
export const TA_WIZARD_SEARCH_LIMIT = 100;

export const TA_WIZARD_ITEMS_PER_PAGE = 10;

export const TA_DROPDOWN_STATUS: NameValueOption[] = [
	{ name: 'ongoing', value: false },
	{ name: 'recent', value: false },
];

export const DDOS_DEFAULT_REGIONS: NameValueOption[] = [
	{ name: 'US', value: 'US' },
	{ name: 'AP', value: 'AP' },
	{ name: 'EMEA', value: 'EMEA' },
];

// ALERT SEARCH WIZARD DROPDOWN CONTROLS

export const TA_DROPDOWN_ALERT_SEVERITY: NameValueOption[] = [
	{ name: 'high', value: true },
	{ name: 'medium', value: false },
	{ name: 'low', value: false },
];

export const TA_DROPDOWN_ALERT_TYPE = [{ name: 'All', value: 'all' }];

export enum TA_ALERT_CLASS_ENUM {
	DOS = 'DoS',
}

export const TA_DROPDOWN_ALERT_CLASSIFICATION: NameValueOption[] = [{ name: 'All', value: 'all' }];

export const TA_DROPDOWN_STATUS_START: NameValueOption[] = [
	{ name: 'Is Before', value: 'before' },
	{ name: 'Is After', value: 'after' },
];

export const TA_DROPDOWN_STATUS_END: NameValueOption[] = [
	{ name: 'Is Before', value: 'before' },
	{ name: 'Is After', value: 'after' },
];

export const TA_DROPDOWN_ALERT_LOW_SEVERITY_PERCENT: NameValueOption[] = [{ name: 'low', value: 0 }];

export const TA_DROPDOWN_ALERT_HIGH_SEVERITY_PERCENT: NameValueOption[] = [{ name: 'high', value: 0 }];

export const TA_TRAFFIC_TABLE_COLUMNS = ['select', 'customer', 'in', 'out', 'total'];

export enum GraphClassType {
	IN = 'in',
	OUT = 'out',
	TOTAL = 'total',
}
export enum GraphType {
	STACKED = 'Stacked',
	BAR = 'Bar',
	PIE = 'Pie',
}

export enum TA_DISPLAY_VALUES {
	TOTAL = 'Total In/Out',
	ONLY_IN = 'In Only',
	ONLY_OUT = 'Out Only',
}

export const TA_DROPDOWN_DISPLAYS: NameValueOption[] = [
	{ name: TA_DISPLAY_VALUES.TOTAL, value: GraphClassType.TOTAL },
	{ name: TA_DISPLAY_VALUES.ONLY_IN, value: GraphClassType.IN },
	{ name: TA_DISPLAY_VALUES.ONLY_OUT, value: GraphClassType.OUT },
];

export const TrafficFacet: { [k: string]: string } = {
	CUSTOMER_COMPARE: '',
	BY_APPLICATION: 'Application',
	BY_TCP: 'TCP_Port',
	BY_UDP: 'UDP_Port',
	BY_TOP_TALKER: 'Top_Destinations',
	BY_TOP_SOURCES: 'Top_Sources',
};

export enum TrafficLayoutRouteParam {
	'ANALYTICS' = 'Customer Compare',
	'APP' = 'by Application',
	'BY-TCP' = 'by TCP',
	'BY-UDP' = 'by UDP',
	'BY-TOP-DEST' = 'by Top Talker',
}

// TRAFFIC FILTERS FORM FIELDS
export type CustomerTrafficFiltersForm = {
	selectedCustomer?: FormControl<SelectedCustomerOption | null>;
	selectedPeriod: FormControl<string>;
	selectedGraphType: FormControl<string>;
	selectedUnits: FormControl<string>;
	selectedDisplay: FormControl<string>;
	selectedVolume: FormControl<string>;
};

export type CustomerTrafficFiltersValues = {
	selectedCustomer?: SelectedCustomerOption;
	selectedPeriod: string;
	selectedGraphType: string;
	selectedUnits: string;
	selectedDisplay: string;
	selectedVolume: string;
};

// MITIGATION SEARCH WIZARD DROPDOWN CONTROLS

export const TA_DROPDOWN_MITIGATION_IP_VERSION = [
	{ name: 'ipv4', value: false },
	{ name: 'ipv6', value: false },
];

export const TA_DROPDOWN_MITIGATION_TYPE = [
	{ name: 'tms', value: false },
	{ name: 'flowspec', value: false },
	{ name: 'blackhole', value: false },
	{ name: 'learning', value: false },
];

export enum TA_DEFAULT_CONFIGS_ENUM {
	PERIOD = 'Today',
	GRAPH_TYPE = 'Stacked',
	UNITS = 'bps',
	DISPLAYS = 'Total In/Out',
	VOLUME = 'Average',
}

export enum TA_DEFAULT_VOLUME_ENUM {
	CURRENT = 'current',
	AVERAGE = 'average',
	MAX = 'max',
	PCT95 = 'pct95',
}

// MITIGATION SUMMARY TABLE COLUMNS

export const MITIGATION_SUMMARY_TABLE_COLUMNS: TableColumn[] = [
	{
		columnName: 'Status',
		dataKey: 'status',
	},
	{
		columnName: 'Mode',
		dataKey: 'mode',
	},
	{
		columnName: 'Alert',
		dataKey: 'alertID',
	},
	{
		columnName: 'Protection Prefixes',
		dataKey: 'protectionPrefixes',
	},
	{
		columnName: 'Managed object',
		dataKey: 'managedObject',
	},
];

// CustomerTraffic Response Type

export type Class = {
	type: string;
	average: number;
	max: number;
	pct95: number;
	timeseries: number[];
};

export type TrafficData = {
	moName: string;
	class: Class[];
	facetId?: string;
	facetName?: string;
	colorVariant?: string;
};

export type ChartTimeSeriesOptions = {
	trafficData: TrafficData[];
	trafficByType: TrafficLayoutRouteParam;
	graphType?: string;
	classType?: string;
	unit?: string;
};

export type CustomerTrafficDataSource = {
	customer: string;
	port?: string;
	colorVariant?: string;
	in: string;
	out: string;
	total: string;
	facetId?: string;
	facetName?: string;
	totalPercent?: string;
};

export type CustomerTrafficGrouped = {
	moName: string;
	trafficData: TrafficData[];
};

export type CustomerTrafficDataObject = {
	defaultZoneName: string;
	duration: number;
	startTime: string;
	endTime: string;
	unit: string;
	trafficData: TrafficData[];
};

export type CustomerTraffic = {
	serviceName: string;
	returnCode: number;
	returnDescription: string;
	dataObject: CustomerTrafficDataObject;
};

export type CustomerTrafficApiPayload = {
	period: TA_PERIOD_ENUMS;
	unit: TrafficUnitValue;
	facet: FacetTypes;
	startDate?: string;
	endDate?: string;
	startTime?: string;
	endTime?: string;
};

export type CustomerTrafficTimeSeries = {
	labelName: string;
	colorVariant: string;
	value: number[] | string[];
	formattedValue?: string;
};

<div [formGroup]="statusFormGroup">

    <div class="status-section">
        <mat-checkbox class="level-checkbox" *ngFor="let s of availableStatuses; let i = index"
            [formControlName]="s.name" [checked]="convertToBooleanInput(s.value)" (change)="onStatusChange($event, s)"
            [matTooltip]="s.name == 'ongoing' ? tooltipOngoingMsg : tooltipRecentMsg">
            {{ s.name }}
        </mat-checkbox>
    </div>

    <div class="is-start d-flex">
        <mat-checkbox class="level-checkbox" formControlName="isStart"> </mat-checkbox>

        <mat-form-field>
            <mat-label>Start</mat-label>
            <mat-select formControlName="start">
                <mat-option *ngFor="let start of statusStart" [value]="start.value">
                    {{ start.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Time</mat-label>
            <input
              formControlName="startTime"
              type="time"
              step="5"
              matInput
            />
        </mat-form-field>

        <mat-form-field class="flex-1">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="datepicker" formControlName="startDate"
                [matDatepickerFilter]="startTimeFilter" />
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker> </mat-datepicker>
        </mat-form-field>
    </div>

    <div class="is-end d-flex">
        <mat-checkbox class="level-checkbox" formControlName="isEnd"> </mat-checkbox>

        <mat-form-field>
            <mat-label>End</mat-label>
            <mat-select formControlName="end">
                <mat-option *ngFor="let end of statusEnd" [value]="end.value">
                    {{ end.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Time</mat-label>
            <input
              formControlName="endTime"
              type="time"
              step="5"
              matInput
            />
        </mat-form-field>

        <mat-form-field class="flex-1">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="datepicker1" formControlName="endDate"
                [matDatepickerFilter]="endTimeFilter" />
            <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
            <mat-datepicker #datepicker1> </mat-datepicker>
        </mat-form-field>
    </div>
</div>

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import moment from 'moment';
import {
	CMOKeyObject,
	CmoIpList,
	Level,
	Mitigation,
	NetworkConnectionKeyObject,
	Site,
} from '../mitigation/mitigation.model';
import {
	Period,
	MitigationBillingResult,
	BillingReportDataSource,
	IpMonitoredReportDataSource,
	MitigationReportDataSource,
	AlertReportsDataSource,
	CmoReportDataSource,
} from './billing.model';
import { Alert, AlertKeyObject } from '../../alert/alert.model';
import { NetworkAddress } from '../../common.model';
import { dateFormat } from '../../common.utils';
import { formatMitigationDuration } from '../mitigation/mitigation.converter';

export const getContractHoursFromPlanDescription = (planDescription: string): number => {
	const match = planDescription.match(/\d+/);
	return match ? parseInt(match[0], 10) : 0;
};

export const getContractedHours = (response: MitigationBillingResult): number => {
	let hours = 0;
	if (response.sites) {
		hours = response.sites
			.flatMap((site) => {
				return site.levels.map((level) => getContractHoursFromPlanDescription(level.name));
			})
			.reduce((acc, cur) => acc + cur, 0);
	}
	return hours;
};

export const getPlanName = (response: MitigationBillingResult): string => {
	let planName = '';
	if (response.sites) {
		planName = response.sites
			.flatMap((site) => {
				return site.levels.map((level) => level.name);
			})
			.shift();
	}
	return planName;
};

export const getBillingReportTimeRange = (period?: Period): [string, string] => {
	const now = moment.utc();
	const isCurrentMonth = period === undefined || period === moment.utc().get('M');
	if (isCurrentMonth) {
		return [now.startOf('month').format('YYYY-MM-DD'), moment.utc().format('YYYY-MM-DD')];
	}

	const isPastMonth = period < now.get('M');
	if (isPastMonth) {
		// If period is a past month, set start and end time accordingly
		const pastMonth = moment.utc().month(period);
		return [pastMonth.startOf('month').format('YYYY-MM-DD'), pastMonth.endOf('month').format('YYYY-MM-DD')];
	}

	// If period is a past month in previous year, set start and end time accordingly
	const pastYearMonth = moment.utc().subtract(12, 'months').month(period);
	return [pastYearMonth.startOf('month').format('YYYY-MM-DD'), pastYearMonth.endOf('month').format('YYYY-MM-DD')];
};

// =====================================================================================================================
// convert models to data sources for reports.
export const getMitigationReport = (mitigation: Mitigation): MitigationReportDataSource => {
	return {
		name: mitigation.mitigationName,
		target: mitigation.ipDiverisonData,
		region: mitigation.region,
		site: mitigation.site,
		startDate: moment.utc(mitigation.attackStartDate).format(dateFormat(mitigation.attackStartDate)),
		endDate: mitigation.attackEndDate
			? moment.utc(mitigation.attackEndDate).format(dateFormat(mitigation.attackEndDate))
			: 'In Progress',
		duration: formatMitigationDuration(mitigation.duration),
		resources: '',
		classification: '',
	};
};

export const getMitigationReports = (mitigations: Mitigation[]): MitigationReportDataSource[] => {
	return mitigations.map((mitigation) => getMitigationReport(mitigation));
};

export const getBillingReport = (site: Site, level: Level): BillingReportDataSource => {
	const duration = `${moment.utc(level.endDate).add(1, 'second').diff(moment.utc(level.startDate), 'days')} days`;
	return {
		name: site.customerName,
		startDate: moment.utc(level.startDate).format('YYYY/MM/DD HH:mm:ss'),
		endDate: moment.utc(level.endDate).format('YYYY/MM/DD HH:mm:ss'),
		duration,
	};
};

export const getBillingReports = (response: MitigationBillingResult): BillingReportDataSource[] => {
	let billingReportData: BillingReportDataSource[] = [];
	if (response.sites) {
		billingReportData = response.sites.flatMap((site) => {
			return site.levels.map((level) => getBillingReport(site, level));
		});
	}
	return billingReportData;
};

export const getCmoReport = (cmoList: CmoIpList, cmoName: string): CmoReportDataSource => {
	return {
		cmoName,
		cmoIp: cmoList.cmoIp,
	};
};

export const getCmoReports = (cmoKeyObject: CMOKeyObject): CmoReportDataSource[] => {
	return cmoKeyObject.keys.map((k) => getCmoReport(cmoKeyObject.objects[k], cmoKeyObject.cmoName));
};

export const getIpMonitorReport = (
	connection: NetworkConnectionKeyObject,
	networkAddress: NetworkAddress
): IpMonitoredReportDataSource => {
	const dataSource: IpMonitoredReportDataSource = {
		name: connection.companyName,
		protectedZones: networkAddress,
		networkType: connection.serviceType,
		agnosticType: connection.agnosticType,
	};

	return dataSource;
};

export const getAlertReport = (alert: Alert): AlertReportsDataSource => {
	const { alertId, classification, moName, region, site } = alert;
	return {
		alertId,
		region,
		site,
		resources: moName,
		classification,
	};
};

export const getAlertReports = (alertKeyObject: AlertKeyObject): AlertReportsDataSource[] => {
	return alertKeyObject.keys.map((k) => getAlertReport(alertKeyObject.objects[k]));
};

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, Observable, Subscription } from 'rxjs';
import { OrUndefineable } from '@src/types/types.utils';
import { SessionStore } from './session/session.store';
import { AlertStore } from './alert/alert.store';
import { MitigationStore } from './report/mitigation/mitigation.store';
import { AdminStore } from './admin/admin.store';
import { CompanyOfferType } from './common.model';

@Injectable({
	providedIn: 'root',
})
export class GlobalGetsStore {
	private globalGetsDoneSub = new BehaviorSubject<boolean>(false);

	private companyDetailSub: OrUndefineable<Subscription>;

	private globalGetsObservables: Observable<unknown>[];

	private globalUpdatesObservables: Observable<unknown>[];

	private firstLoad = true;

	constructor(
		private sessionStore: SessionStore,
		private alertStore: AlertStore,
		private mitigationStore: MitigationStore,
		// private knowledgebaseStore: KnowledgeBaseStore,
		private adminStore: AdminStore
	) {
		// setup global gets observables.
		this.globalGetsObservables = [
			this.alertStore.alertKeyObject$,
			this.mitigationStore.mitigationKeyObject$,
			this.mitigationStore.syncedStatusNetworkConnectionKeyObjects$,
			this.adminStore.liaisonContactKeyObject$,
			// this.knowledgebaseStore.knowledgebaseDocFile$,
		];

		// listening to all.
		combineLatest(this.globalGetsObservables).subscribe(([alert, mitigation, syncedNetworkStatus, liaison]) => {
			const offerType: number | undefined = this.sessionStore.currentCompanyDetail?.OfferType;
			if (
				(alert || (!!offerType && offerType === CompanyOfferType.REACTIVE)) &&
				mitigation &&
				syncedNetworkStatus &&
				liaison
			) {
				this.globalGetsDoneSub.next(true);
			}
		});

		this.sessionStore.isAuthenticatedAndReady$.pipe(filter((isReady) => isReady)).subscribe(() => {
			if (this.firstLoad) {
				this.firstLoad = false;
			} else {
				return;
			}
			// unsubscribe before resubscribe it again.
			this.companyDetailSub?.unsubscribe();

			// speed the portal by loading all the gets globally.
			this.companyDetailSub = this.sessionStore.currentCompanyDetail$.subscribe((company) => {
				if (company) {
					if (this.sessionStore.currentCompanyDetail.OfferType !== CompanyOfferType.REACTIVE) {
						this.alertStore.getAlerts();
					}
					this.mitigationStore.getMitigations();
					this.mitigationStore.getSyncedMitigationStatus();
					this.adminStore.getLiaisonContacts();
					// this.knowledgebaseStore.getKnowledgeBaseDocs();
				}
			});
		});
	}

	get globalGetsDone$() {
		return this.globalGetsDoneSub.asObservable();
	}

	get globalGetsDone() {
		return this.globalGetsDoneSub.value;
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderStore {
	private count = 0;

	private readonly httpProgressSub = new BehaviorSubject<boolean>(false);

	get httpProgress$() {
		return this.httpProgressSub.asObservable();
	}

	get httpProgress() {
		return this.httpProgressSub.value;
	}

	increaseRemoteCallCount() {
		this.count += 1;
		if (this.count > 0) {
			this.httpProgressSub.next(true);
		}
	}

	decreaseRemoteCallCount() {
		this.count -= 1;
		if (this.count <= 0) {
			this.count = 0;
			this.httpProgressSub.next(false);
		}
	}
}

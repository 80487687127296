<div class="ddos-dataset-list">
  <mat-list class="list-item" [ngClass]="{'list-name-hidden': !displayName}">
    <mat-list-item *ngIf="displayName" class="dataset-name">{{ characterization.dataSetName }}</mat-list-item>
    <mat-list-item>{{ characterization.dataSetItem }}</mat-list-item>

    <mat-list-item class="dataset-percent-block">
      <mat-progress-bar [value]="characterization.dataSetItemPercent"> </mat-progress-bar>
      <span class="dataset-percent-value">{{ characterization.dataSetItemPercent }}%</span>
    </mat-list-item>
  </mat-list>
</div>

<div *ngIf="sidebarItems?.length > 0" #drawer id="ddos-sidebar" class="ddos-sidebar-container" data-cy="sidebar-nav-menu">
  <mat-nav-list>
    <ng-container *ngFor="let el of sidebarItems; trackBy: trackByItems">
      <nav class="nav-item" role="button">
        <a
          (click)="onRouteClick(el)"
          (keyup.enter)="onRouteClick(el)"
          [ngClass]="{ 'parent-route': el.parent, active: el.isActive }"
          class="nav-title"
          tabindex="0"
          role="button"
        >
          {{ el.title }}
          <mat-icon *ngIf="el?.children.length && el.isActive">arrow_drop_up</mat-icon>
        </a>

        <ng-container *ngFor="let child of el?.children; trackBy: trackByItems">
          <a
            *ngIf="el.isActive"
            class="sub-nav-item"
            (keyup.enter)="onChildRouteClick(child, el)"
            (click)="onChildRouteClick(child, el)"
            [ngClass]="{ active: child.isActive }"
            tabindex="0"
            role="button"
          >
            {{ child.title }}
          </a>
        </ng-container>
      </nav>
    </ng-container>
  </mat-nav-list>
</div>

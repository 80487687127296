/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NumberInput } from '@angular/cdk/coercion';
import { TableColumn, TableRowClick, TableDataSource } from '@src/app/stores/common.model';
import { tryParseJson } from '@src/app/utils/key-object';
import { OrUndefineable } from '@src/types/types.utils';
import moment from 'moment';

const DEFAULT_PAGINATION_SIZES: number[] = [5, 10, 15];
const DEFAULT_DATE_YEAR = 2001;
const DEFAULT_NO_DATA_MESSAGE = 'No data available';

@Component({
	selector: 'ddos-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DdosTableTableComponent implements OnInit, AfterViewInit, OnDestroy {
	protected tblHeaders: string[];

	protected tblColumns: TableColumn[] = [];

	protected tblDataSource = new MatTableDataSource<TableDataSource>([]);

	protected selection = new SelectionModel<TableDataSource>(true, []);

	protected showPaginate = false;

	protected rowActionIconList: { icon: string; tooltip: string }[];

	protected rowActionSingleIcon: string;

	private _data: TableDataSource[];

	private _destroy$ = new Subject<boolean>();

	private _tableHasBeenInitialized = false;

	private _simpleSearchText: OrUndefineable<string> = undefined;

	private _flatPlainObjectFilterJson: OrUndefineable<string> = undefined;

	@ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

	@ViewChild(MatSort) set matSort(sort: MatSort) {
		this.tblDataSource.sort = sort;
	}

	// Table input properties.
	@Input() isPageable = false;

	@Input() isSortable = false;

	@Input() isFilterable = false;

	@Input() isStickyHeader = false;

	@Input() isSelectable = false;

	@Input() hasRowLink = false;

	@Input() hasIdLink = false;

	@Input() rowActionIcon:
		| string
		| {
				icon: string;
				tooltip: string;
		  }[] = [];

	@Input() rowActionMenu: string[] = [];

	@Input() paginationSizes: number[] = DEFAULT_PAGINATION_SIZES;

	private _defaultPageSize: NumberInput = DEFAULT_PAGINATION_SIZES[1];

	@Input() defaultSortingColumn: string;

	@Input() defaultSortingDirection: 'asc' | 'desc' | undefined;

	@Input() set defaultPageSize(size) {
		this._defaultPageSize = size;
		if (this.matPaginator) {
			this.matPaginator.pageSize = +size;
			this.matPaginator._changePageSize(+size);
			this.tblDataSource.paginator.firstPage();
		}
	}

	get defaultPageSize(): NumberInput {
		return this._defaultPageSize;
	}

	@Input() set data(data: TableDataSource[]) {
		this._data = data;
		if (this._tableHasBeenInitialized) {
			this.initDataSource();
		}
	}

	@Input() set columns(columns: TableColumn[]) {
		this.tblColumns = columns;
	}

	@Input() set flatPlainObjectFilterJson(value: string) {
		this._flatPlainObjectFilterJson = value;
		// just use to trigger the filter, we don't use * here.
		this.tblDataSource.filter = '*';
	}

	@Input() set searchText(value: string) {
		this._simpleSearchText = value;
		// just use to trigger the filter, we don't use * here.
		this.tblDataSource.filter = '*';
	}

	@Input() noDataMessage = DEFAULT_NO_DATA_MESSAGE;

	@Output() sort: EventEmitter<Sort> = new EventEmitter<Sort>();

	@Output() rowAction = new EventEmitter<TableRowClick>();

	@Output() onRowClick = new EventEmitter<TableRowClick>();

	@Output() onSelectRow = new EventEmitter<TableDataSource[]>();

	@Output() connect = new EventEmitter<Observable<TableDataSource[]>>();

	@Output() initialized = new EventEmitter<boolean>();

	@Output() paginatorEvent = new EventEmitter<PageEvent>();

	ngOnInit() {
		this.selection.changed.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onSelectRow.emit(this.selection.selected);
		});
	}

	ngAfterViewInit() {
		this.initTableColumns();
		this.initDataSource();
		this._tableHasBeenInitialized = true;
		this.initialized.emit(true);
	}

	ngOnDestroy() {
		this._destroy$.next(true);
		this._destroy$.unsubscribe();
	}

	initTableColumns() {
		const columns = [...this.tblColumns];
		this.tblHeaders = columns.map((tableColumn: TableColumn) => tableColumn.columnName);
		if (typeof this.rowActionIcon === 'string') {
			this.rowActionSingleIcon = this.rowActionIcon;
			this.tblHeaders = [...this.tblHeaders, this.rowActionSingleIcon];
		} else if (Array.isArray(this.rowActionIcon)) {
			this.rowActionIconList = [...this.rowActionIcon];
			this.tblHeaders = [...this.tblHeaders, ...this.rowActionIconList.map((item) => item.icon)];
		}

		this.tblHeaders = this.isSelectable ? ['select', ...this.tblHeaders] : this.tblHeaders;
		this.tblHeaders = this.rowActionMenu?.length ? [...this.tblHeaders, 'actionMenu'] : this.tblHeaders;
	}

	private initDataSource() {
		this.tblDataSource = new MatTableDataSource<TableDataSource>(this._data);
		this.tblDataSource.sortingDataAccessor = this.sortingDataAccessor;
		if (this.matPaginator) {
			this.matPaginator.pageSize = this.matPaginator.pageSize ?? (DEFAULT_PAGINATION_SIZES[1] as NumberInput);
			this.tblDataSource.paginator = this.matPaginator;
		}
		this.showPaginate = this.tblDataSource.data.length > 0;
		this.tblDataSource.filterPredicate = this.filterPredicate;
		this.connect.emit(this.tblDataSource.connect().asObservable());
		this._tableHasBeenInitialized = true;
		// This line is for triggering the search event after the filterpredicate has been set, it only happens once
		this.searchText = this._simpleSearchText;
		this.tblDataSource.filter = '*';
	}

	sortingDataAccessor: (row: TableDataSource, sortHeaderId: string) => string | number = (
		row: TableDataSource,
		sortHeaderId: string
	): string | number => {
		switch (sortHeaderId) {
			case 'startDate':
			case 'startTime':
			case 'endDate':
			case 'endTime':
				return row[sortHeaderId] ? this.formatDateForSorting(row[sortHeaderId].toString()) : '';

			case 'duration':
				return row[sortHeaderId] ? this.formatDurationForSorting(row[sortHeaderId].toString()) : '';

			default:
				if (typeof row[sortHeaderId] === 'string') {
					return row[sortHeaderId].toString().toLowerCase();
				}
				if (typeof row[sortHeaderId] === 'bigint' || typeof row[sortHeaderId] === 'number') {
					return Number(row[sortHeaderId]);
				}
				return row[sortHeaderId].toString();
		}
	};

	private formatDateForSorting(rawDate: string): string {
		let formattedDate = '';
		let date = '';
		const splittedDate: string[] = rawDate.toString().split(' - ');
		if (splittedDate?.length > 1) {
			[date] = splittedDate;
		} else {
			[date] = splittedDate;
		}
		const dateIncludesYear: boolean = DEFAULT_DATE_YEAR < moment.utc(date).year();
		formattedDate = dateIncludesYear
			? moment.utc(date).format()
			: moment.utc(date).set('year', moment.utc().year()).format();
		return formattedDate;
	}

	private formatDurationForSorting(rawDuration: string): string {
		let formattedDuration = '';
		const mitigationWithDaysDuration = /[0-9]+d,\s\d\d:\d\d:\d\d/;
		const mitigationWithoutDaysDuration = /\d\d:\d\d:\d\d/;
		if (mitigationWithDaysDuration.test(rawDuration)) {
			const duratioWithDays: string[] = rawDuration.split('d, ');
			formattedDuration = `${duratioWithDays[0].padStart(3, '0')}:${duratioWithDays[1]}`;
		} else if (mitigationWithoutDaysDuration.test(rawDuration)) {
			formattedDuration = `000:${rawDuration}`;
		} else {
			const splittedDate: string[] = rawDuration.toString().split(' ');
			if (splittedDate?.length > 2) {
				const date: string = splittedDate.slice(0, 3).join(' ');
				formattedDuration = moment.utc(date).format();
			} else {
				formattedDuration = rawDuration;
			}
		}
		return formattedDuration;
	}

	protected hasData() {
		return this._data?.length > 0;
	}

	getIconColor(element: TableColumn, iconOptions: string | string[]) {
		if ('dataKeyIconColor' in element) {
			return element.dataKeyIconColor;
		}

		return iconOptions;
	}

	filterPredicate = (dataSource: TableDataSource, _: string): boolean => {
		if (!this._simpleSearchText && !this._flatPlainObjectFilterJson) {
			return true;
		}

		// try to parse the filter if it was constructed from a plain object.
		// otherwise, it was a simple string, skip to the next if.
		const filterObject = tryParseJson(this._flatPlainObjectFilterJson);
		let matched = true;
		if (filterObject && typeof filterObject === 'object' && Object.keys(filterObject).length > 0) {
			matched = Object.entries(filterObject).every(([key, filterValue]) => {
				const dataValue = dataSource[key];
				return (
					!filterValue ||
					filterValue === 'All' ||
					dataValue.toString().toLowerCase().includes(filterValue.toLowerCase())
				);
			});
		}

		if (!this._simpleSearchText) {
			return matched;
		}

		matched =
			matched &&
			Object.entries(dataSource).some(([, value]) => {
				return value?.toString().toLowerCase().includes(this._simpleSearchText.toLowerCase());
			});

		return matched;
	};

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.tblDataSource.data?.length;
		return numSelected === numRows;
	}

	toggleAllRows() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.selection.select(...this.tblDataSource.data);
		}
	}

	sortTable(sortParameters: Sort) {
		this.sort.emit({
			...sortParameters,
			active: this.tblColumns.find((column) => column.dataKey === sortParameters.active).dataKey,
		});
	}

	emitRowAction(row: TableDataSource, icon?: string) {
		this.rowAction.emit({ actionIcon: icon, rowValue: row });
	}

	onClick(row: TableDataSource, icon: string | string[]) {
		if (!this.hasIdLink) {
			this.onRowClick.emit({ actionIcon: icon, rowValue: row });
		}
	}

	onPaginatorChange(page: PageEvent) {
		this.paginatorEvent.emit(page);
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { KeyObjects } from '@src/types/types.utils';

export const toPlainObjectByKey = <T>(source: T[], key: keyof T): { [k: string]: T } => {
	const plainObject = source.reduce((acc, current) => {
		acc[current[key].toString()] = current;
		return acc;
	}, {});
	return plainObject;
};

export const toKeyObject = <T>(source: T[], key: keyof T): KeyObjects<T> => {
	const objects = toPlainObjectByKey(source, key);
	const allKeys = Object.keys(objects);
	const keyObject = {
		snapshotKeys: allKeys,
		keys: allKeys,
		objects,
	};

	return keyObject;
};

export const cloneKeyObject = <T>(source: KeyObjects<T>): KeyObjects<T> => {
	const { keys, snapshotKeys, objects, ...remainder } = source;
	const cloned = {
		...remainder,
		snapshotKeys: [...snapshotKeys],
		keys: [...keys],
		objects: {
			...keys.reduce((acc, k) => ({ ...acc, [`${k}`]: objects[k] }), {}),
		},
	};

	return cloned;
};

export const tryParseJson = (str: string): { [k: string]: string } => {
	try {
		return JSON.parse(str) as { [k: string]: string };
	} catch (e) {
		return undefined;
	}
};

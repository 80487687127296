/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';
import { Alert, AlertKeyObject } from '@src/app/stores/alert/alert.model';

@Pipe({
	name: 'alertToAlerts',
})
export class AlertToAlertsPipe implements PipeTransform {
	transform(alertKeyObject: AlertKeyObject): Alert[] {
		return alertKeyObject.keys.map((k) => alertKeyObject.objects[k]);
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
} from '@angular/core';

@Directive({
	selector: '[ddos-radio-button]',
})
export class DdosRadioButtonDirective implements AfterViewInit, OnChanges {
	@Input('selected') selected: boolean;

	elementClass = 'active-radio';

	constructor(
		private renderer: Renderer2,
		public el: ElementRef
	) {}

	ngAfterViewInit() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selected.currentValue) {
			this.renderer.addClass(this.el.nativeElement, this.elementClass);
		} else {
			this.renderer.removeClass(this.el.nativeElement, this.elementClass);
		}
	}

	@HostListener('click', ['$event']) onClick(event) {
		const activeElement = this.el.nativeElement.classList.contains(this.elementClass);

		if (!activeElement) {
			this.renderer.addClass(event.target, this.elementClass);
		} else {
			this.renderer.removeClass(event.target, this.elementClass);
		}
	}
}

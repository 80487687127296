/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap, throwError } from 'rxjs';
import * as URLS from '@src/app/stores/api.urls';
import { SessionStore } from '@src/app/stores/session/session.store';
import { assertValue } from '@src/app/utils/assert';
import { Undefineable } from '@src/types/types.utils';
import { BDID } from '@src/app/stores/api.urls';
import {
	LiaisonContacts,
	CreateOrUpdateLiaisonContact,
	LiaisonContactActionResponse,
	LiaisonContactKeyObject,
	ContactInfo,
	LiaisonContact,
} from './admin.model';
import { ApiResponse } from '../common.model';
import { getLiaisonContractKeyObject } from './admin.converter';

@Injectable({
	providedIn: 'root',
})
export class AdminStore {
	private readonly liaisonContactKeyObjectSub = new BehaviorSubject<Undefineable<LiaisonContactKeyObject>>(undefined);

	private readonly defaultContactZoneSub = new BehaviorSubject<string>(undefined);

	constructor(
		private http: HttpClient,
		private sessionStore: SessionStore
	) {}

	get liaisonContactKeyObject$() {
		return this.liaisonContactKeyObjectSub.asObservable();
	}

	get liaisonContactKeyObject() {
		return this.liaisonContactKeyObjectSub.value;
	}

	get defaultContactZone$() {
		return this.defaultContactZoneSub.asObservable();
	}

	get defaultContactZone() {
		return this.defaultContactZoneSub.value;
	}

	findLiaisonContractById(liaisonContactId: string) {
		return this.liaisonContactKeyObject.objects[liaisonContactId];
	}

	getLiaisonContacts() {
		const getLiaisonCustomerDetailsUrl = URLS.getApiUrl(
			URLS.CUSTOMER_DETAILS_BY_LIAISONS,
			assertValue(this.sessionStore.currentCompany.companyID)
		);

		// notify subscribers that it is reloading.
		this.liaisonContactKeyObjectSub.next(undefined);
		this.http
			.get<ApiResponse<LiaisonContacts>>(getLiaisonCustomerDetailsUrl)
			.pipe(
				catchError((e: Error) => {
					// to complete the global gets.
					this.liaisonContactKeyObjectSub.next({
						objects: {},
						keys: [],
						snapshotKeys: [],
					});
					return throwError(() => e);
				}),
				tap((response) => {
					this.liaisonContactKeyObjectSub.next(getLiaisonContractKeyObject(response));
				})
			)
			.subscribe();
	}

	getContactByBdId(bdId: string): Observable<ContactInfo> {
		const getContactByBdIdUrl = URLS.getApiUrl(URLS.GET_CONTACT_BY_BDID).replace(BDID, bdId);
		return this.http.get<ContactInfo>(getContactByBdIdUrl);
	}

	checkExistingBdid(bdid: string): LiaisonContact | undefined {
		return Object.values(this.liaisonContactKeyObject?.objects).find((contact) => contact.loginId === `bd-${bdid}`);
	}

	createLiaisonContact(payload: CreateOrUpdateLiaisonContact): Observable<LiaisonContactActionResponse> {
		const createLiaisonContactUrl = URLS.getApiUrl(
			URLS.CREATE_LIAISON,
			assertValue(this.sessionStore.currentCompany.companyID)
		);
		return this.http
			.post<ApiResponse<LiaisonContactActionResponse>>(createLiaisonContactUrl, payload)
			.pipe(map((ddosResponse) => ddosResponse.dataObject));
	}

	updateLiaisonContact(
		payload: CreateOrUpdateLiaisonContact,
		liaisonId: string
	): Observable<LiaisonContactActionResponse> {
		const updateLiaisonContactUrl = URLS.getApiUrl(
			URLS.UPDATE_LIAISONS,
			assertValue(this.sessionStore.currentCompany.companyID)
		).replace(URLS.LIAISON_ID, liaisonId);
		return this.http
			.put<ApiResponse<LiaisonContactActionResponse>>(updateLiaisonContactUrl, payload)
			.pipe(map((ddosResponse) => ddosResponse.dataObject));
	}

	deleteLiaisonCustomer(liaisonId: string): Observable<LiaisonContactActionResponse> {
		const deleteLiaisonCustomerUrl = URLS.getApiUrl(
			URLS.DELETE_LIAISONS,
			assertValue(this.sessionStore.currentCompany.companyID)
		).replace(URLS.LIAISON_ID, liaisonId);
		return this.http
			.delete<ApiResponse<LiaisonContactActionResponse>>(deleteLiaisonCustomerUrl)
			.pipe(map((ddosResponse) => ddosResponse.dataObject));
	}
}

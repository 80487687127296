/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { assertValue } from '@src/app/utils/assert';
import { environment } from '../../environments/environment';

export const COMPANY_ID = '{companyId}';
export const REQUEST_ID = '{requestId}';
export const ALERT_ID = '{alertId}';
export const NETWORK_CONNECTION_ID = '{networkConnectionId}';
export const ORDER_SSPP_GUID = '{orderSSPPGuid}';
export const LIAISON_ID = '{liaisonId}';
export const FILENAME = '{filename}';
export const BDID = '{bdId}';

export const getApiUrl = (requestPath: string, companyId?: number): string => {
	return companyId
		? `${environment.API_BACKEND_URL}${requestPath}`.replace(COMPANY_ID, assertValue(companyId).toString())
		: `${environment.API_BACKEND_URL}${requestPath}`;
};

const version = ''; // example: /1.0, /2.0, /v1.0, always prefix with slash.
const customerUrl = `/ddos${version}/customer`;
const customerWithRequiredCompanyIdUrl = `${customerUrl}/${COMPANY_ID}`;
const adminUrl = `/ddos${version}/admin`;
const adminWithRequiredCompanyIdUrl = `${adminUrl}/${COMPANY_ID}`;
const knowledgeBaseDocsUrl = `/ddos${version}/knowledgeBaseDoc`;
const authBaseUrl = `/auth${version}`;

export const LOGOUT_URL = `${authBaseUrl}/logout`;

export const GET_CONTACT_BY_BDID = `${customerUrl}/validate/${BDID}`;
export const GET_REQUEST_DETAILS = `${customerWithRequiredCompanyIdUrl}/request/${REQUEST_ID}`;
export const GET_CUSTOMER_COMPANY_INFO = `${customerUrl}-info`;
export const GET_CUSTOMER_DETAILS = `${customerWithRequiredCompanyIdUrl}/detail`;

export const GET_TRAFFIC_INFO = `${customerWithRequiredCompanyIdUrl}/report/traffic`;
export const CUSTOMER_ALERTS = `${customerWithRequiredCompanyIdUrl}/report/alert`;
export const CUSTOMER_ALERT_BY_ID = `${customerWithRequiredCompanyIdUrl}/report/alert/${ALERT_ID}`;
export const MITIGATION_REPORT = `${customerWithRequiredCompanyIdUrl}/report/mitigation`;
export const ONGOING_MITIGATION_REPORT = `${MITIGATION_REPORT}?mitigationPeriod=1`;
export const MITIGATION_SUMMARY_REPORT = `${customerWithRequiredCompanyIdUrl}/report/mitigation/traffic?{startTime}&{endTime}&{mitigationName}`;
export const GET_BILLING_REPORT = `${customerWithRequiredCompanyIdUrl}/report/mitigation/${ORDER_SSPP_GUID}/billing?{startTime}&{endTime}`;

export const GET_NETWORK_CONNECTIONS = `${customerWithRequiredCompanyIdUrl}/networkConnection`;
export const GET_NETWORK_CONNECTION = `${customerWithRequiredCompanyIdUrl}/networkConnection/${NETWORK_CONNECTION_ID}`;
export const ADD_NETWORK_CONNECTIONS = `${customerWithRequiredCompanyIdUrl}/networkConnection`;
export const UPDATE_NETWORK_CONNECTIONS = `${customerWithRequiredCompanyIdUrl}/networkConnection/{${NETWORK_CONNECTION_ID}}`;
export const BULK_UPDATE_NETWORK_CONNECTIONS = `${customerWithRequiredCompanyIdUrl}/networkConnection/bulk/update`;

export const MITIGATION_BY_ACTION = `${customerWithRequiredCompanyIdUrl}/mitigation`;

export const KNOWLEDGE_BASE_DOCS = `${knowledgeBaseDocsUrl}`;
export const UPLOAD_KNOWLEDGE_BASE_DOC = `${knowledgeBaseDocsUrl}`;
export const DOWNLOAD_KNOWLEDGE_BASE_DOC = `${knowledgeBaseDocsUrl}/${FILENAME}`;

export const CUSTOMER_DETAILS_BY_LIAISONS = `${adminWithRequiredCompanyIdUrl}/liaison`;
export const CREATE_LIAISON = `${adminWithRequiredCompanyIdUrl}/liaison`;
export const DELETE_LIAISONS = `${adminWithRequiredCompanyIdUrl}/liaison/${LIAISON_ID}`;
export const UPDATE_LIAISONS = `${adminWithRequiredCompanyIdUrl}/liaison/${LIAISON_ID}`;

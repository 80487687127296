/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as URLS from '@src/app/stores/api.urls';
import { OrUndefineable } from '@src/types/types.utils';
import { assertValue } from '@src/app/utils/assert';
import { environment } from '@src/environments/environment';
import { ApiResponse } from '../common.model';
import { Userinfo, CustomerInfo, CompanyDetail, RELOADED, CompanyInfo } from './session.model';

@Injectable({
	providedIn: 'root',
})
export class SessionStore {
	private readonly userSub = new BehaviorSubject<OrUndefineable<Userinfo>>(undefined);

	private readonly assignedCompaniesSub = new BehaviorSubject<OrUndefineable<CompanyInfo[]>>(undefined);

	private readonly currentCompanyInfoSub = new BehaviorSubject<OrUndefineable<CompanyInfo>>(undefined);

	private readonly isAdminRoleSub = new BehaviorSubject<boolean>(false);

	private readonly companyDetailSub = new BehaviorSubject<OrUndefineable<CompanyDetail>>(undefined);

	private readonly isAuthenticatedAndReadySub = new BehaviorSubject<boolean>(false);

	private readonly beforeReload = new BehaviorSubject<boolean>(false);

	private readonly AfterReload = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient) {}

	get user$() {
		return this.userSub.asObservable();
	}

	get user(): OrUndefineable<Userinfo> {
		return this.userSub.value;
	}

	get isAdminRole$() {
		return this.isAdminRoleSub.asObservable();
	}

	get isAdminRole() {
		return this.isAdminRoleSub.value;
	}

	get assignedCompanies$() {
		return this.assignedCompaniesSub.asObservable();
	}

	get assignedCompanies() {
		return this.assignedCompaniesSub.value;
	}

	get currentCompany$() {
		return this.currentCompanyInfoSub.asObservable();
	}

	get currentCompany(): OrUndefineable<CompanyInfo> {
		return this.currentCompanyInfoSub.value;
	}

	get currentCompanyDetail$() {
		return this.companyDetailSub.asObservable();
	}

	get currentCompanyDetail(): OrUndefineable<CompanyDetail> {
		return this.companyDetailSub.value;
	}

	get currentCompanySite() {
		return (
			this.companyDetailSub.value.ssppDataList?.length && this.companyDetailSub.value.ssppDataList[0]?.siteNameSSPP
		);
	}

	get isAuthenticatedAndReady() {
		return this.isAuthenticatedAndReadySub.value;
	}

	get isAuthenticatedAndReady$() {
		return this.isAuthenticatedAndReadySub.asObservable();
	}

	get beforeReload$() {
		return this.beforeReload.asObservable();
	}

	get afterReload() {
		return this.AfterReload.value;
	}

	changeCompanyId(companyId: number) {
		if (companyId !== this.currentCompany?.companyID) {
			const companyInfo = this.assignedCompanies?.find((c) => c.companyID === companyId);
			if (companyInfo) {
				this.getCustomerDetails(companyId).subscribe((companyDetail) => {
					this.currentCompanyInfoSub.next(assertValue(companyInfo, "companyId should've already in the list"));
					this.companyDetailSub.next(companyDetail);
					this.verifyIfAuthenticatedAndReady();
				});
			}
		}
	}

	initSession() {
		const url = new URL(window.location.href);
		const redirectUri = 'redirect_uri';
		if (url.searchParams.has(redirectUri)) {
			window.location.href = `${url.origin}/auth/login?${redirectUri}=${url.searchParams.get(redirectUri)}`;
		} else {
			window.location.href = `${url.origin}/auth/login?${redirectUri}=${url.href}`;
		}
	}

	endSession(): void {
		// invalidate all the browser current session.
		const message = 'You will be redirecting away from the DDOS portal to the Business center, are you sure?';
		// eslint-disable-next-line no-restricted-globals, no-alert
		if (confirm(message)) {
			this.http
				.get(URLS.LOGOUT_URL, {
					responseType: 'text',
				})
				.pipe(
					finalize(() => {
						window.location.assign(environment.BUSINESS_CENTER_URL);
					})
				)
				.subscribe();
		}
	}

	loadUserData() {
		this.getCustomerInfo();
	}

	private getCustomerInfo() {
		const url = URLS.getApiUrl(URLS.GET_CUSTOMER_COMPANY_INFO);
		this.http
			.get<ApiResponse<CustomerInfo>>(url)
			.pipe(
				tap((res) => {
					const customerInfo = res.dataObject;
					this.isAdminRoleSub.next(customerInfo.isAdmin);
					this.assignedCompaniesSub.next(customerInfo.customerList);
					this.userSub.next(customerInfo.userinfo);
					const storedCompanyInfo = this.getCurrentSettings<CompanyInfo>('companyInfo');
					const selectedCompany = storedCompanyInfo ?? customerInfo.customerList[0];
					this.currentCompanyInfoSub.next(
						assertValue(selectedCompany, `No company was assigned for ${this.user.email}`)
					);
					this.getCustomerDetails(this.currentCompany.companyID).subscribe((companyDetail) => {
						this.companyDetailSub.next(companyDetail);
						this.verifyIfAuthenticatedAndReady();
						localStorage.removeItem(RELOADED);
					});
				})
			)
			.subscribe();
	}

	private getCustomerDetails(companyId: number): Observable<CompanyDetail> {
		const url = URLS.getApiUrl(URLS.GET_CUSTOMER_DETAILS, companyId);
		return this.http.get<ApiResponse<CompanyDetail>>(url).pipe(map((res) => res.dataObject));
	}

	private verifyIfAuthenticatedAndReady() {
		if (this.user && this.currentCompany && this.currentCompanyDetail && this.assignedCompanies) {
			this.isAuthenticatedAndReadySub.next(true);
		}
	}

	ongoingReload() {
		this.beforeReload.next(true);
	}

	afterPageReload(value: boolean) {
		this.AfterReload.next(value);
	}

	saveCurrentSettings<T>(name: string, settings: T) {
		localStorage.setItem(name, JSON.stringify(settings));
	}

	clearCurrentSettings(name: string) {
		localStorage.removeItem(name);
	}

	getCurrentSettings<T>(name: string): T | undefined {
		const settingsStr = localStorage.getItem(name);
		return settingsStr !== 'undefined' ? (JSON.parse(settingsStr) as T) : undefined;
	}
}

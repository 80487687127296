/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertCharacterization } from '@src/app/stores/alert/alert.model';

@Component({
	selector: 'ddos-ui-list',
	templateUrl: './ddos-list.component.html',
	styleUrls: ['./ddos-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DdosListComponent {
	@Input() characterization: AlertCharacterization;

	@Input() isElevated = false;

	@Input() displayName = true;
}

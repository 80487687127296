/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'propertyGetter',
})
export class PropertyGetterPipe implements PipeTransform {
	transform(object: Record<string, unknown>, keyName: string): unknown {
		return object[keyName];
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialog } from '../../confirm-dialog/confirm.component';

export type ConfirmDialogData = {
	title: string;
	message: string;
	confirmActionText: string;
	cancelActionText?: string;
	closable?: boolean;
};

export const DDOS_CONFIRM_DIALOG_DEFAULT_CONFIGS: MatDialogConfig = {
	width: '500px',
	disableClose: true,
};

export const DDOS_CONFIRM_DIALOG_NO_DATA: ConfirmDialogData = {
	title: 'No Data Returned',
	message: 'No data was returned from the reporting system – please contact Technical Support for details.',
	confirmActionText: 'Go back',
	closable: true
};

export const DDOS_CONFIRM_DIALOG_UNKNOWN_ERROR: ConfirmDialogData = {
	title: 'Unknown Error',
	message: 'No data was returned – please contact Technical Support for details.',
	confirmActionText: 'OK',
	closable: false
};

export const DDOS_CONFIRM_DIALOG_INVALID_ERROR: ConfirmDialogData = {
	title: 'Invalid Request',
	message: 'Bad Request – Something is missing.',
	confirmActionText: 'OK',
	closable: true
};

export const DDOS_CONFIRM_DIALOG_UNAUTHORIZED_ERROR: ConfirmDialogData = {
	title: 'Unauthorized User',
	message: 'Unauthorized User or token expired - You will be redirected to the login page.',
	confirmActionText: 'OK',
	closable: false
};

export const DDOS_CONFIRM_DIALOG_FORBIDDEN_ERROR: ConfirmDialogData = {
	title: 'Forbidden',
	message: 'Unauthorized access - You will be redirected to the login page.',
	confirmActionText: 'OK',
	closable: false
};

@Injectable({
	providedIn: 'root',
})
export class ConfirmDialogService {
	private dialogInstances: MatDialogRef<ConfirmDialog>[] = [];

	constructor(private dialog: MatDialog) {}

	openConfirmDialog(data: ConfirmDialogData, config = DDOS_CONFIRM_DIALOG_DEFAULT_CONFIGS): MatDialogRef<ConfirmDialog, any> {
		this.dialog.closeAll();
		const dialogRef = this.dialog.open(ConfirmDialog, { data, ...config });
		this.dialogInstances.push(dialogRef);

		return dialogRef;
	}

	getDialogInstances(): MatDialogRef<ConfirmDialog>[] {
		return this.dialogInstances;
	}

	closeDialog(dialogRef: MatDialogRef<ConfirmDialog>) {
		dialogRef.close();
	}

	closeAllDialogs() {
		this.dialogInstances.forEach((dialogRef) => dialogRef.close());
	}

	hasActiveDialogs(): boolean {
		return this.dialogInstances.length > 0;
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { ApiResponse } from '@app/stores/common.model';

export const assertValue = <T>(value: T, errorMessage?: string): T => {
	if (typeof value === 'undefined' || value === null ) {
		console.error(errorMessage || 'value cannot be null or undefined');
	}

	return value;
};

export const assertHttpResponse = <T>(response: ApiResponse<T>): boolean => {
	return !(typeof response === 'undefined' || response.returnCode === 1)
}

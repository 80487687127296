<div class="header">
  <h2 mat-dialog-title>{{ data?.title }}</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>{{ data?.message }}</p>
</div>

<div mat-dialog-actions [align]="'end'">
  <button *ngIf="data?.cancelActionText" (click)="cancel()" mat-button [mat-dialog-close]="false" color="primary">
    {{ data?.cancelActionText }}
  </button>
  <button (click)="confirm()" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    {{ data?.confirmActionText }}
  </button>
</div>

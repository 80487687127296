/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Routes } from '@angular/router';
import { AppLayoutRouting } from '@src/app/routing-constants';
import { BdidNotFoundComponent } from '@app/components/shared/bdid-not-found/bdid-not-found.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';

function reload(path: string) {
	setTimeout(() => {
		window.location.href = `${window.location.origin}/${path}`;
	}, 0);
}

export const AppRoutes: Routes = [
	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full',
	},
	{
		path: `${AppLayoutRouting.HOME}`,
		loadChildren: () =>
			import('@src/app/components/home/home.module')
				.then((m) => m.HomeModule)
				.catch((err: Error) => {
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.HOME);
					}
					return err;
				}),
		data: { title: `${AppLayoutRouting.HOME}` },
	},
	{
		path: `${AppLayoutRouting.ALERTS}`,
		redirectTo: '/traffic/alerts',
		pathMatch: 'full',
	},
	{
		path: `${AppLayoutRouting.TRAFFIC}`,
		loadChildren: () =>
			import('@src/app/components/traffic/traffic.module')
				.then((m) => m.TrafficModule)
				.catch((err: Error) => {
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.TRAFFIC);
					}
					return err;
				}),
		data: { title: `${AppLayoutRouting.TRAFFIC}` },
	},
	{
		path: `${AppLayoutRouting.ZONES}`,
		loadChildren: () =>
			import('@src/app/components/zones/zones.module')
				.then((m) => m.ZonesModule)
				.catch((err: Error) => {
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.ZONES);
					}
					return err;
				}),
		data: { title: `${AppLayoutRouting.ZONES}` },
	},
	{
		path: `${AppLayoutRouting.REPORTS}`,
		loadChildren: () =>
			import('@src/app/components/reports/reports.module')
				.then((m) => m.ReportsModule)
				.catch((err: Error) => {
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.REPORTS);
					}
					return err;
				}),
		data: { title: `${AppLayoutRouting.REPORTS}` },
	},
	{
		path: `${AppLayoutRouting.ADMIN}`,
		loadChildren: () =>
			import('@src/app/components/admin/admin.module')
				.then((m) => m.AdminModule)
				.catch((err: Error) => {
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.ADMIN);
					}
					return err;
				}),
		data: { title: `${AppLayoutRouting.ADMIN}` },
	},
	{
		path: `${AppLayoutRouting.KNOWLEDGE}`,
		loadChildren: () =>
			import('@src/app/components/knowledge/knowledge.module')
				.then((m) => m.KnowledgeModule)
				.catch((err: Error) => {
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.KNOWLEDGE);
					}
					return err;
				}),
		data: { title: `${AppLayoutRouting.KNOWLEDGE}` },
	},
	{
		path: `${AppLayoutRouting.SETTINGS}`,
		loadChildren: () =>
			import('@src/app/components/settings/settings.module')
				.then((m) => m.SettingsModule)
				.catch((err) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					if (err?.name === 'ChunkLoadError') {
						reload(AppLayoutRouting.SETTINGS);
					}
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return
					return err;
				}),
		data: { title: `${AppLayoutRouting.SETTINGS}` },
	},
	{
		path: `${AppLayoutRouting.USER_ERROR}`,
		component: PageNotFoundComponent,
	},
	{
		path: `${AppLayoutRouting.BDID_ERROR}`,
		component: BdidNotFoundComponent,
	},
	{
		path: '**',
		component: PageNotFoundComponent,
	},
];

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import moment from 'moment';
import { capitalize } from 'lodash';
import { FormGroup } from '@angular/forms';
import { toKeyObject } from '@src/app/utils/key-object';
import {
	Alert,
	AlertCharacterization,
	AlertChartDatParams,
	AlertChartParams,
	AlertCollection,
	AlertDetail,
	AlertDetailTitleParams,
	AlertKeyInformation,
	AlertKeyObject,
	AlertTitleParams,
	AlertTrafficDataset,
	ChartDataset,
	DataSet,
	Item,
} from './alert.model';
import { ApiResponse } from '../common.model';
import { assertValue } from '../../utils/assert';
import { dateFormat, dateFormatRange, formatUnits } from '../common.utils';

export const formatBits = (bits: number, decimals = 2) => {
	if (!+bits) {
		return '0';
	}

	const k = 1000; // 1024
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];

	const i = Math.floor(Math.log(bits) / Math.log(k));

	return `${parseFloat((bits / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getFormatAlert = (alert: Alert): Alert => {
	const { days, hours, minutes, alertStartAt } = parseAlertTimeStamps(alert.startTime, alert.endTime);
	const duration = measureAlertDuration(days, hours, minutes, alert.endTime, alertStartAt);

	return {
		...alert,
		duration,
	};
};

const parseAlertType = (type: string): string => {
	return type
		.toLowerCase()
		.split('_')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');
};

export const parseAlertTimeStamps = (startTime: string, endTime: string) => {
	const alertStartAt = moment.utc(startTime).format('MMM DD HH:mm y');
	const alertEndAt = endTime ? moment.utc(endTime) : moment.utc();
	const timeDifference = moment.duration(alertEndAt.diff(moment.utc(startTime))).abs();
	//	Total days of the alert
	const days = alertEndAt.diff(moment.utc(startTime), 'days');
	const hours = timeDifference.hours().toString().padStart(2, '0');
	const minutes = timeDifference.minutes().toString().padStart(2, '0');
	const seconds = timeDifference.seconds().toString().padStart(2, '0');
	return { days, hours, minutes, seconds, alertStartAt };
};

export const measureAlertDuration = (
	days: number,
	hours: string | number,
	minutes: string | number,
	endTime: string,
	alertStartAt: string
) => {
	const alertStartAtFormatted: string = moment.utc(alertStartAt).format(dateFormat(moment.utc(alertStartAt)));
	const daysDuration: string = days > 0 ? `${days}d, ` : '';
	const alertDuration = `${daysDuration}${hours}:${minutes}`;
	let modifiedEndTime = '';
	if (endTime) {
		const endTimeFormat = dateFormatRange(alertStartAt, endTime);
		modifiedEndTime = moment.utc(endTime).format(endTimeFormat);
	}
	const modifiedStartTime = endTime
		? `${alertStartAtFormatted} - ${modifiedEndTime} (${alertDuration})`
		: `${alertStartAtFormatted} (${alertDuration})`;
	return modifiedStartTime;
};

export const createAlertTitle = (params: AlertTitleParams, defaultZoneName: string) => {
	const { alertType, direction, targetIPAddress, alertOwnedBy, misuseTypes } = params;
	const alertTypeModified = parseAlertType(alertType);
	let alertTitle = `${direction} ${alertTypeModified} to ${targetIPAddress} using ${alertOwnedBy}}`;

	// Comparing against API alertType value
	if (alertType === 'dos_profiled_router') {
		alertTitle = `${alertTitle}.
		Description: ${defaultZoneName}_${targetIPAddress}`;
	} else if (alertType === 'dos_host_detection') {
		alertTitle = `${alertTitle}.
		Misuse Types: ${misuseTypes.join(', ')}`;
	}

	return alertTitle;
};

export const createAlertDetailTitle = ({ defaultZoneName, startTime, endTime }: AlertDetailTitleParams) => {
	const { days, hours, minutes, alertStartAt } = parseAlertTimeStamps(startTime, endTime);
	const duration = measureAlertDuration(days, hours, minutes, endTime, alertStartAt);

	return {
		title: defaultZoneName
			.toUpperCase()
			.split('_')
			.map((s) => s)
			.join(' '),
		duration,
	};
};

export const alertOwnedBy = (moName: string, defaultZoneName: string) => {
	return moName ? `${moName} (parent: ${defaultZoneName})` : defaultZoneName;
};

export const getAlertTitle = (alert: Alert): string => {
	const { impactBps, severityPercent, severityThreshold } = alert;
	const sThreshold = `${formatBits(severityThreshold)}`;
	const impBps = `${formatBits(impactBps)}`;
	const newTitle = `${severityPercent}% of ${sThreshold} ${impBps} `;
	return newTitle;
};

export const findMaxTimeSeriesItem = (prev: Item, current: Item) => {
	const prevLastElement = prev.average;
	const currentLastElement = current.average;

	return prevLastElement > currentLastElement ? prev : current;
};

export const fetchTopMisuse = (alertDetails: AlertDetail[]) => {
	return alertDetails
		?.map((dataset) => dataset.dataSets.find((set) => set.name === 'Misuse Types'))
		.map((dataset) => dataset.items)
		.reduce((i) => i)
		.reduce((prev, current) => findMaxTimeSeriesItem(prev, current));
};

export const generateTrafficDatasets = (
	alertDatasetItem: Item,
	topMisUseDataset: Item,
	alertDataset: DataSet
): AlertCharacterization => {
	const currentDatasetValue: number = alertDatasetItem.average;
	const currentTopMisuseValue: number = topMisUseDataset.average;
	const dataSetItemPercent: string =
		currentDatasetValue && currentTopMisuseValue
			? ((currentDatasetValue / currentTopMisuseValue) * 100).toFixed(2)
			: '0.00';

	return {
		dataSetName: alertDataset.name,
		dataSetItem: `${alertDatasetItem.itemName}`,
		datasetValue: currentDatasetValue ? `${formatBits(currentDatasetValue)}` : null,
		dataSetItemPercent,
	};
};

export const getAlertCharacterizations = (alertDetails: AlertDetail[]): AlertCharacterization[] => {
	const topMisUseDataset = fetchTopMisuse(alertDetails);

	return alertDetails
		?.map((alert) => {
			return alert.dataSets.map((dataset) => {
				const maxDatasetItem = dataset.items.reduce((prev, current) => findMaxTimeSeriesItem(prev, current));
				return generateTrafficDatasets(maxDatasetItem, topMisUseDataset, dataset);
			});
		})
		.flat();
};

export const generateStackedLineChartData = (alertDetails: AlertDetail[], limit?: number): AlertChartParams => {
	const datasets: ChartDataset[] = [];
	let chartLabels: string[] = [];

	// Loop through each trafficData object and extract data for the chart
	alertDetails.forEach((traffic: AlertDetail) => {
		traffic.dataSets.forEach((dataSet) => {
			let sortedItems: Item[];
			if (limit && dataSet.items.length > limit) {
				sortedItems = [...dataSet.items].sort((a, b) => b.average - a.average).slice(0, limit);
			}
			(sortedItems ?? dataSet.items).forEach((item) => {
				const alertStartTime = new Date(traffic.startTime).getTime() / 1000;

				chartLabels = item.timeseries.map((_value, j) =>
					moment.utc((alertStartTime + (traffic.duration / (item.timeseries.length - 1)) * j) * 1000).format('HH:mm:ss')
				);

				const color = `rgba(${Math.floor(Math.random() * 201)}, ${Math.floor(Math.random() * 201)}, ${Math.floor(
					Math.random() * 201
				)}, 0.41)`;

				datasets.push({
					label: `${dataSet.name} - ${item.itemName}`,
					data: item.timeseries,
					backgroundColor: color,
					fill: false,
				});
			});
		});
	});

	return { datasets, chartLabels };
};

export const generateTotalStackedLineChartData = (alertDetails: AlertDetail[]): AlertChartParams => {
	const datasets: ChartDataset[] = [];
	let chartLabels: string[] = [];

	const alertDetail: AlertDetail = alertDetails?.length && alertDetails[0];
	const dataset: DataSet =
		alertDetail?.dataSets?.length && alertDetail.dataSets.find((dataset) => dataset.name === 'Misuse Types');
	const timeseriesTotalValues: number[] =
		dataset?.items?.find((item) => item.itemName === 'Total Traffic')?.timeseries || [];

	if (timeseriesTotalValues) {
		const color = 'rgba(101, 101, 101, 0.41)';
		datasets.push({
			label: '',
			data: timeseriesTotalValues,
			backgroundColor: color,
			fill: true,
		});

		const alertStartTime = new Date(alertDetail.startTime).getTime() / 1000;

		chartLabels = getChartLabels(timeseriesTotalValues, alertStartTime, alertDetail.duration);
	}

	return { datasets, chartLabels };
};

const getChartLabels = (timeseries: number[], startTime: number, duration: number): string[] => {
	return timeseries.map((_value, j) =>
		moment.utc((startTime + (duration / (timeseries.length - 1)) * j) * 1000).format('HH:mm:ss')
	);
};

export const getAlertTrafficDatasets = (alertDetails: AlertDetail[]): AlertTrafficDataset[] => {
	const topMisUseDataset = fetchTopMisuse(alertDetails);
	const trafficDatasets: AlertTrafficDataset[] = [];

	alertDetails.forEach((alert) => {
		alert.dataSets.forEach((dataset) => {
			const datasetItems = dataset.items
				.map((item) => generateTrafficDatasets(item, topMisUseDataset, dataset))
				.filter((d) => d.dataSetItemPercent !== '0');

			const datasetItem: AlertTrafficDataset = {
				dataSetName: dataset.name,
				dataSetItems: datasetItems,
			};

			trafficDatasets.push(datasetItem);
		});
	});
	return trafficDatasets;
};

export function getElementsEqualToValue(array: unknown[], key: string, value: unknown): unknown[] {
	return array.filter((element) => element[key] === value);
}

export const getImportanceAlertChartDatasets = (alerts: Alert[]): AlertChartDatParams => {
	let startTime = moment.utc().subtract(5, 'months').startOf('month').toISOString();
	const endTime = moment.utc().startOf('month').toISOString();
	const timeSlots: string[] = [];
	const highImportance: number[] = [];
	const mediumImportance: number[] = [];
	const lowImportance: number[] = [];
	const chartDatasets: ChartDataset[] = [];
	const lastSixMonthsAlerts: { [key: string]: Alert[] } = {};

	alerts.forEach((alert) => {
		const alertDate = moment.utc(alert.startTime).startOf('day');
		if (alertDate.diff(startTime, 'days') >= 0) {
			const alertMonth = moment.utc(alertDate).format('MMM');
			if (!lastSixMonthsAlerts[alertMonth]) {
				lastSixMonthsAlerts[alertMonth] = [];
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			lastSixMonthsAlerts[alertMonth].push(alert);
		}
	});
	while (moment.utc(startTime).isSameOrBefore(endTime, 'month')) {
		const month = moment.utc(startTime).format('MMM');
		timeSlots.push(month);
		const alertsOnMonth = typeof lastSixMonthsAlerts[month] !== 'undefined' ? lastSixMonthsAlerts[month] : [];
		highImportance.push(getElementsEqualToValue(alertsOnMonth, 'importance', 'high').length);
		mediumImportance.push(getElementsEqualToValue(alertsOnMonth, 'importance', 'medium').length);
		lowImportance.push(getElementsEqualToValue(alertsOnMonth, 'importance', 'low').length);
		startTime = moment.utc(startTime).add(1, 'M').toISOString();
	}
	chartDatasets.push({ data: highImportance, label: 'high', borderColor: '#FF6384' });
	chartDatasets.push({ data: mediumImportance, label: 'medium', borderColor: '#FFC663FF' });
	chartDatasets.push({ data: lowImportance, label: 'low', borderColor: '#2EA123FF' });

	return { datasets: chartDatasets, chartLabels: timeSlots };
};

export const hasValueInAlertSearchForm = (formGroup: FormGroup): boolean => {
	const formValues = formGroup.getRawValue() as Record<string, unknown>;
	return Object.values(formValues).some((value) => {
		if (typeof value === 'object' && value !== null) {
			return Object.values(value).some((innerValue) => Boolean(innerValue));
		}
		return Boolean(value);
	});
};

export const getAlertKeyInformation = (alert: Alert): AlertKeyInformation[] => {
	const {
		severityPercent,
		severityThreshold,
		severityUnit,
		misuseTypes,
		impactBps,
		impactPps,
		targetIPAddress,
		direction,
		importance,
		moName,
	} = assertValue(alert);

	return [
		{
			severity: capitalize(importance),
			maxSeverity: `${severityPercent}% of ${severityThreshold} ${severityUnit}`,
			topMisuseType: `${misuseTypes?.join(' ')}`,
			maxImpact: `${formatUnits(impactBps, 'bps')} / ${formatUnits(impactPps, 'pps')}`,
			directions: `${direction}`,
			misuseType: `${misuseTypes?.join(' ')}`,
			managedObject: `${moName}`,
			target: `${targetIPAddress}`,
		} as AlertKeyInformation,
	];
};

export const getAlertKeyObject = (response: ApiResponse<AlertCollection>): AlertKeyObject => {
	return {
		defaultZoneName: assertValue(response.dataObject).defaultZoneName,
		...toKeyObject(response.dataObject.alertData, 'alertId'),
	};
};

<div id="ddos-main-container">
  <header class="top-bar" *ngIf="isAuthenticatedAndReady$ | async">
    <div class="logo-area">
      <img class="logo" src="assets/images/logo.svg" alt="Company logo" />
      <h1 class="title">AT&T <span class="fw-normal">DDoS Portal</span></h1>
    </div>
    <div class="ddos-ui-top-menubar">
      <nav mat-tab-nav-bar data-cy="top-nav-menu">
				<ng-container *ngIf="tabItems$ | async as tabItems">
					<a mat-tab-link *ngFor="let el of tabItems;" [routerLink]="el.path" [routerLinkActive]="'active'">
						{{ el.title }}
					</a>
				</ng-container>

        <div class="user-area">
          <p class="currentTime">
            <ddos-ui-timer></ddos-ui-timer>
          </p>
          <button mat-tab-link class="profile"
                  [matMenuTriggerFor]="menu"
                  *ngIf="user$ | async"
                  [matTooltip]="userBtnTooltip"
                  aria-controls="ddos-user-menu-options"
                  data-cy="user-menu">
            <mat-icon>person</mat-icon>
          </button>
        </div>

        <mat-menu #menu="matMenu" class="ddos-user" id="ddos-user-menu-options">
          <ng-container *ngIf="user$ | async as user">
            <button mat-menu-item>
              <span class="user-email" data-cy="user-email">{{ user.email }}</span>
            </button>
					</ng-container>
					<ng-container *ngIf="currentCompany$ | async as currentCompany">
						<mat-radio-group [value]="currentCompany.companyID" (change)="onCustomerSelectionChange($event)" data-cy="companies-list">
							<ng-container *ngFor="let company of assignedCompanies$ | async">
								<button mat-menu-item>
									<mat-radio-button [value]="company.companyID">
										<div class="user-details">
											<span class="company-name">{{ company.companyName }}</span> &nbsp; | &nbsp;
											<span class="company-id">{{ company.companyID }}</span>
										</div>
									</mat-radio-button>
								</button>
							</ng-container>
						</mat-radio-group>

          <button
            *ngIf="currentCompany.offerType === CompanyOfferType.NON_REACTIVE"
            mat-menu-item
            (click)="openArborPortalModal()"
            data-cy="arbor-portal-link-button"
            >Customer Arbor Portal</button>
					</ng-container>

					<button mat-menu-item (click)="onLogout()" data-cy="logout-button">Exit DDoS Defense Portal</button>
        </mat-menu>
      </nav>
    </div>
  </header>

  <main class="ddos-body">
    <ng-content></ng-content>
  </main>
</div>

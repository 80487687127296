/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable, NgZone } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpHeaders,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { SessionStore } from '@src/app/stores/session/session.store';
import { assertValue } from '@src/app/utils/assert';
import {
	ConfirmDialogService,
	DDOS_CONFIRM_DIALOG_FORBIDDEN_ERROR,
	DDOS_CONFIRM_DIALOG_INVALID_ERROR,
} from '@app/components/shared/services/dialog/confirm-dialog.service';
import { Router } from '@angular/router';
import { AppLayoutRouting } from '@app/routing-constants';
import { GET_CUSTOMER_COMPANY_INFO } from '@app/stores/api.urls';

@Injectable()
export class DdosHttpInterceptor implements HttpInterceptor {
	constructor(
		private sessionStore: SessionStore,
		private zone: NgZone,
		private dialogService: ConfirmDialogService,
		private router: Router
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let ddosHeaders = {};
		if (this.sessionStore.currentCompany) {
			ddosHeaders = {
				[`x-ddos-bdid`]: `${assertValue(this.sessionStore.currentCompany.bdId)}`,
				[`x-ddos-companyID`]: `${assertValue(this.sessionStore.currentCompany.companyID)}`,
			};
		}

		const req = request.clone({
			headers: new HttpHeaders({
				...ddosHeaders,
			}),
		});

		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse | undefined) => {
				switch (error?.status) {
					case 400: {
						this.dialogService.openConfirmDialog(DDOS_CONFIRM_DIALOG_INVALID_ERROR);
						break;
					}
					case 401: {
						this.sessionStore.initSession();
						break;
					}
					case 403: {
						this.sessionStore.initSession();
						this.dialogService.openConfirmDialog(DDOS_CONFIRM_DIALOG_FORBIDDEN_ERROR);
						break;
					}
					case 404: {
						this.goToErrorPage(error);
						break;
					}
					case 502: {
						this.goToErrorPage(error);
						break;
					}
					case 504: {
						this.goToErrorPage(error);
						break;
					}
					// eslint-disable-next-line prettier/prettier
					default: {
						/* empty */
					}
				}

				return throwError(() => error);
			})
		);
	}

	private goToErrorPage(error: HttpErrorResponse) {
		if (error.url.includes(GET_CUSTOMER_COMPANY_INFO)) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			this.router.navigate([`/${AppLayoutRouting.BDID_ERROR}`], { queryParams: { code: error.status } });
		}
	}
}

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppLayoutRouting, SidebarChild, SidebarItem, TrafficLayoutRouting } from '@src/app/routing-constants';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'ddos-ui-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Input() sidebarOpened: boolean;

	private sidebarStarted = false;

	private _sidebarItems: SidebarItem[];

	@Input() set sidebarItems(items: SidebarItem[]) {
		if (items?.length > 0 && !this.sidebarStarted) {
			this.sidebarStarted = true;
			this._sidebarItems = items;
			this.setInitialActiveElement();
		}
	}

	get sidebarItems(): SidebarItem[] {
		return this._sidebarItems;
	}

	private destroy$ = new Subject<boolean>();

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe((event: NavigationEnd) => this.captureTopNavTrafficEvent(event));
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	setActiveElement(currentItem: SidebarItem) {
		this._sidebarItems = this._sidebarItems?.map((s) => ({
			...s,
			isActive: s.path === currentItem.path,
			children: this.desactivateChildren(s.children),
		}));
		this.cd.detectChanges();
	}

	setActiveChildElement(currentItem: SidebarItem, childRouteItem: SidebarChild) {
		this._sidebarItems = this._sidebarItems?.map((s) => {
			const childrenActivated: SidebarChild[] = s.children?.map((c) => ({
				...c,
				isActive: c.path === childRouteItem.path,
			}));

			return s.path !== currentItem.path ? s : { ...s, children: [...childrenActivated] };
		});
		this.cd.detectChanges();
	}

	async onRouteClick(item: SidebarItem) {
		await this.router.navigate([item.path]).then(() => {
			this.setActiveElement(item);
		});
	}

	async onChildRouteClick(childRouteItem: SidebarChild, currentItem: SidebarItem) {
		await this.router.navigate([childRouteItem.path]).then(() => {
			this.setActiveChildElement(currentItem, childRouteItem);
		});
	}

	trackByItems(_index: number, item: SidebarItem | SidebarChild) {
		return `${item.path}${item.isActive ? 'active' : ''}`;
	}

	private setInitialActiveElement() {
		const { url } = this.router;
		const currentElement: SidebarItem = this.getElementByUrl<SidebarItem>(this.router.url, this.sidebarItems);
		if (!currentElement) {
			this.sidebarItems
				.filter((s) => s.children?.length > 0)
				?.forEach((c) => {
					const childElement = this.getElementByUrl<SidebarChild>(url, c.children);
					if (childElement) {
						this.setActiveElement(c);
						this.setActiveChildElement(c, childElement);
					}
				});
		} else {
			this.setActiveElement(currentElement);
		}
	}

	private getElementByUrl<T extends SidebarItem | SidebarChild>(url: string, menuElements: T[]): T | undefined {
		return menuElements?.find((item) => item.path.includes(url));
	}

	private captureTopNavTrafficEvent(event: NavigationEnd) {
		let topNavigationFound: string;
		if (event.url === `/${AppLayoutRouting.TRAFFIC}`) {
			topNavigationFound = `/${AppLayoutRouting.TRAFFIC}/${TrafficLayoutRouting.ANALYTICS}`;
		} else if (event.url === `/${AppLayoutRouting.REPORTS}/${AppLayoutRouting.ALERT_REPORT}`) {
			topNavigationFound = `/${AppLayoutRouting.REPORTS}/${AppLayoutRouting.ALERT_REPORT}`;
		} else if (event.url === `/${AppLayoutRouting.REPORTS}/${AppLayoutRouting.MITIGATION_REPORT}`) {
			topNavigationFound = `/${AppLayoutRouting.REPORTS}/${AppLayoutRouting.MITIGATION_REPORT}`;
		}
		if (topNavigationFound) {
			const trafficAnalyticsElement: SidebarItem = this.getElementByUrl<SidebarItem>(
				topNavigationFound,
				this.sidebarItems
			);
			if (trafficAnalyticsElement) {
				this.setActiveElement(trafficAnalyticsElement);
			}
		}
	}

	private desactivateChildren(children: SidebarChild[]): SidebarChild[] {
		return children.map((c) => ({ ...c, isActive: false }));
	}
}

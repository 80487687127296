/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponseBase,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { LoaderStore } from '@src/app/stores/loader/loader.store';

@Injectable({
	providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
	constructor(private loaderStore: LoaderStore) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this.loaderStore.increaseRemoteCallCount();
		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponseBase) {
					this.loaderStore.decreaseRemoteCallCount();
				}
			}),
			catchError((error: HttpErrorResponse | undefined) => {
				this.loaderStore.decreaseRemoteCallCount();
				return throwError(() => error);
			})
		);
	}
}

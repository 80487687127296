/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatestWith, filter } from 'rxjs';
import {
	SidebarItem,
	TabItem,
	AppLayoutRouting,
	TrafficSidebarsForRegularUser,
	TabsForRegularUser,
	TabsForReactiveUser,
	ReportSidebarsForRegularUser,
} from '@src/app/routing-constants';
import { OrUndefineable } from '@src/types/types.utils';
import { CompanyOfferType } from '../common.model';
import { SessionStore } from '../session/session.store';
import { CompanyDetail } from '../session/session.model';

export type Breadcrumb = { label: string; url: string };

/**
 * Navigation Store
 */
@Injectable({
	providedIn: 'root',
})
export class AppLayoutStore {
	private readonly sidebarsSub = new BehaviorSubject<OrUndefineable<SidebarItem[]>>([]);

	private readonly tabsSub = new BehaviorSubject<OrUndefineable<TabItem[]>>(TabsForRegularUser);

	constructor(
		private router: Router,
		private sessionStore: SessionStore
	) {
		this.sessionStore.isAuthenticatedAndReady$
			.pipe(filter((isReady) => isReady))
			.subscribe(() => this.buildTabItemsByOfferType());

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				combineLatestWith(this.sessionStore.currentCompanyDetail$)
			)
			.subscribe(([_, company]) => this.buildSidebarMenuItems(company));
	}

	get regularUserSidebarItems$() {
		return this.sidebarsSub.asObservable();
	}

	get regularUserSidebarItems() {
		return this.sidebarsSub.value;
	}

	get tabItems$() {
		return this.tabsSub.asObservable();
	}

	get tabItems() {
		return this.tabsSub.value;
	}

	private buildTabItemsByOfferType() {
		const company = this.sessionStore.currentCompanyDetail;
		if (company && company.OfferType === CompanyOfferType.REACTIVE) {
			this.tabsSub.next(TabsForReactiveUser);
		} else {
			// Based on Karen Steele, the Bandwidth tier and regular tier
			// should see the same tabs/sidebars UI.
			this.tabsSub.next(TabsForRegularUser);
		}
	}

	private buildSidebarMenuItems(company: CompanyDetail) {
		const currentRoute = this.router.url;
		let sidebars: SidebarItem[] = [];
		if (company.OfferType !== CompanyOfferType.REACTIVE) {
			if (currentRoute.includes(AppLayoutRouting.TRAFFIC)) {
				sidebars = TrafficSidebarsForRegularUser;
			} else if (currentRoute.includes(AppLayoutRouting.REPORTS)) {
				sidebars = ReportSidebarsForRegularUser;
			}
		}

		this.sidebarsSub.next(sidebars);
	}
}

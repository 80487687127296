/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	NgZone,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Component({
	selector: 'ddos-ui-timer',
	templateUrl: './timer.component.html',
	styleUrls: ['./timer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerComponent implements OnInit {
	now: Date;

	currentTime: string;

	@Input() type: any = '';

	@Input() format = 'HH:mm:ss';

	@ViewChild('clock')
	public currentTimeElRef: ElementRef;

	@ViewChild('clock1')
	public currentTimeElRef1: ElementRef;

	systemTimezone: string;

	UTCTimezone: string;

	constructor(
		private zone: NgZone,
		private renderer: Renderer2,
		private datePipe: DatePipe
	) {}

	ngOnInit(): void {
		const systemTimezone = moment.tz.guess();
		this.systemTimezone = moment.tz(systemTimezone).zoneAbbr();
		this.UTCTimezone = moment.tz().zoneAbbr();
		this.zone.runOutsideAngular(() => {
			setInterval(() => {
				this.renderer.setProperty(
					this.currentTimeElRef1.nativeElement,
					'textContent',
					new Date().toUTCString().split(' ')[4]
				);
				this.now = new Date();
				const currentTime = this.datePipe.transform(new Date(), this.format);
				this.renderer.setProperty(this.currentTimeElRef.nativeElement, 'textContent', currentTime);
				// }
			}, 1);
		});
	}
}

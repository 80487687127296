<div class="error-container">
  <div class="error-section">
    <h2>Well this is awkward....</h2>
    <div class="img">
      <img src="../../../../assets/images/404.png" alt="404 Error" title="404 Error" />
    </div>
    <h4>{{ errorMessage }}</h4>
    <p>
      The page you are looking for does not exist <br />
      Please check the link and try again.
    </p>
    <br />
    <p>For more information, please see <a (click)="navigateToPortal()"> Click to access AT&T DDoS Defense Portal </a></p>
  </div>
</div>
